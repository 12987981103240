import React, { useEffect, useState } from 'react'
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import Sidebar from '../../layouts/Sidebar'
import Navbar from '../../layouts/Navbar'
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'
import { restorentData, updateRestorentStatus,getAdminNotification,getAdminReadNotification } from '../../services/AuthApi'
import { Loader } from 'rsuite';
import moment from 'moment'

export default function Notifaction() {
  let [data, setData] = useState()
  const [loading, setLoading] = useState(true);
  let token = localStorage.getItem("Token");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totaldata, setTotaldata] = useState(0);
  const [getNotification, setNotification] = useState(0);

  const [limit, setLimit] = useState(10);
  const [nhit, setNhit] = useState(0);
  const [filtername, setFiltername] = useState()


  const headers = {
    'Authorization': `Bearer ${token}`
  };

  useEffect(() => {
    fetchNotification();
  }, [currentPage]);

  const getCouponData = async (da) => {
    try {
      const data = await restorentData(da)
      setData(data.data.data)
      setCurrentPage(data.data.pageNo)
      setTotalPages(data.data.totalPage)
      setLimit(data.data.limit)
      setTotaldata(data.data.total)
      setNhit(data.data.nextHit)
      setLoading(false);
    } catch (error) {
      toast.error("internal server error")
    }
  }

  const handleNextPage = () => {
    getCouponData(`pageNo=${nhit}&limit=${limit}`)
  };
  const filterData = (e, name) => {
    setFiltername(e.target.textContent)
    getCouponData(`pageNo=${currentPage}&limit=${limit}&sortBy=${name}&sortOrder=1`)
  }

  const StatusData = (e, name) => {
    setFiltername(e.target.textContent)
    getCouponData(`pageNo=${currentPage}&limit=${limit}&status=${name}&sortOrder=1`)
  }

  const fetchNotification = async () => {

      const response = await getAdminNotification({pageNo:currentPage,limit:10});
      setNotification(response.data);
      setTotalPages(response.totalPage);
      setLimit(response.limit);
      setLoading(false);
      console.log('response',response)

     const res = await getAdminReadNotification();
     console.log('res',res)

  }







  const chanegStatus = async (id) => {
    await updateRestorentStatus(id).then(res => {
      getCouponData(`pageNo=${currentPage}&limit=${limit}`)
      toast.success(res.message)
    }).catch(error => {
      toast.error(error.response.data.message)
    })

  }

  const deleteResturants = async (id) => {
    try {
      const requestDelete = {
        method: 'DELETE',
        url: `${process.env.REACT_APP_API_URL}api/admin/restaurants/${id}`,
        headers: headers,
      };
      Swal.fire({
        title: 'Are you sure?',
        text: "Are you sure want to Delete this manager Id?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        // console.log(result,'==============')
        if (result.isConfirmed) {
          axios(requestDelete)
            .then(response => {
              getCouponData()
              Swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
              )
            })
            .catch(error => {
              toast.error(error.response.data.message)
            })
        }
      })
    } catch (error) {
      toast.error("internal server error")
    }
  }

  return (
    <>
      <ToastContainer />
      <div className="appview">
        {/* sidebar */}
        <Sidebar />
        {/* \ sidebar */}
        {/* right Content */}
        <div className="right_content">
          {/* Navbar */}
          <Navbar />
          {/* \ Navbar */}
          {/* deshboad */}

          {loading ? (
            <Loader backdrop content="Loading..." vertical size="lg" />
          ) : (
            <>
              <section className="wrapper">
                <div className="container-fluid">
                  <div className="wrapper_header d-flex justify-content-between align-items-center">
                    <div>
                      <h2 className="comp_heading">Notifactions</h2>
                      <h4 className="comp_sub_heading">
                        Here is your notification list 
                      </h4>
                    </div>
                   
                  </div>
                </div>
              </section>
              <section className="wrapper">
                <div className="container-fluid">
                <div className="row gy-4">
                  
         



            {getNotification.map((Notifaction)=> {

              return (<div className="col-md-12 mx-auto ">
              <div className="notify_card">
                  <img src={(Notifaction.ImageSrc) ? Notifaction.ImageSrc : 'assets/images/user3.png'} alt=""/>
                  <div className="notify_body">
                    
                      <h4> {Notifaction.title}   <span > {' '} {moment(Notifaction.createdAt).fromNow()}</span></h4>
                      <h6> Title : {Notifaction.Name} </h6>
                      <p>{Notifaction.description}</p>
                  </div>
              </div>
              </div>);
              })}
            
        </div>
                </div>
                <div className="table_footer">
                      <div className="row align-items-center g-3">
                        <div className="col-md-6 text-md-start text-center">
                          <p>Showing {currentPage} from {totaldata} data</p>
                        </div>
                        <div className="col-md-6">
                          <nav aria-label="Page navigation example">
                            <ul className="pagination ms-auto me-md-0 me-auto">
                              <li className="page-item">
                                <Link  className="page-link" onClick={() => {(currentPage > 1) ? setCurrentPage(currentPage-1 ) : setCurrentPage(currentPage) }} >
                                  <span aria-hidden="true">
                                    <i className="fa-solid fa-angles-left" />
                                  </span>
                                </Link>
                              </li>
                              {[...Array(totalPages)].map((e, i) =>
                                <li className="page-item" key={i}>
                                  <Link   onClick={() => setCurrentPage(i+1 )} className={currentPage == i + 1 ? "page-link active" : "page-link"}>
                                    {i + 1}
                                  </Link>
                                </li>
                              )}
                              <li className="page-item">
                                <Link  onClick={() => {(currentPage > totalPages ) ? setCurrentPage(currentPage+1 ) : setCurrentPage(currentPage) }}  className="page-link">
                                  <span aria-hidden="true">
                                    <i className="fa-solid fa-angles-right" />
                                  </span>
                                </Link>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      </div>
                      </div>
              </section>
              {/* modal */}
            </>
          )}


          <div
            className="modal fade"
            id="manager-delete"
            tabIndex={-1}
            aria-labelledby="manager-deleteLabel"
            aria-hidden="true"
          >

          </div>
          {/* \ deshboad */}
        </div>
        {/* \ right Content */}
      </div>

    </>



  )
}
