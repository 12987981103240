import React, { useEffect, useState,createRef } from 'react'
import Sidebar from '../../layouts/Sidebar'
import Navbar from '../../layouts/Navbar'
import { edit_advertising, fileUpload, update_advertising, getCatData } from '../../services/AuthApi'
import { useParams, useNavigate,Link } from 'react-router-dom';
import { Loader } from 'rsuite';
import DemoImage from './../../images/banner.png';
import Modal from 'react-bootstrap/Modal';
import Cropper, { ReactCropperElement } from "react-cropper";
import Button from 'react-bootstrap/Button';
import "cropperjs/dist/cropper.css";
const defaultSrc ="https://raw.githubusercontent.com/roadmanfong/react-cropper/master/example/img/child.jpg";

export default function Edit_advertising() {
  
    const navigate = useNavigate()
    const params = useParams();
    const [catData, setCatData] = useState()
    const [showcat, setShowcat]= useState()
     const [dis, setDis] = useState()

    const [details, setDetails] = useState({})
    const [loading, setLoading] = useState(true);
    const [getImages, setImages] = useState([DemoImage,DemoImage,DemoImage])
    const [show, setShow] = useState(false);
    const [getFile, setFile] = useState();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [image, setImage] = useState(defaultSrc);
    const [myBlob, setBlob] = useState('');
    const [cropData, setCropData] = useState("#")
    const cropperRef = createRef();
    useEffect(() => {
        get_Data()
        get_Cat_Data()
    }, []);

    const get_Data = async () => {
        try {
            let data = await edit_advertising(params.id)
            setDetails(data.data);
            // if(data.data.images.length)


            setImages([(data.data.images[0]? data.data.images[0] :DemoImage ),(data.data.images[1]? data.data.images[1] :DemoImage ),(data.data.images[2]? data.data.images[2] :DemoImage )])
            setDis(data.data.discount.value)
            setShowcat(data.data.food_category[0])
            setLoading(false);
        } catch (error) {
            console.log(error)
        }

    }

    const imguplaod = async () => {
    
        await  getCropData();
          try {
              let formData = new FormData();
              formData.append("file", myBlob,"myfile.jpeg");
              let data = await fileUpload(formData);
              console.log(getFile,data.data.data.filePath)
              setCropData('#');
              switch (getFile) {
                  case 0:
                    setImages( [data.data.data.filePath,getImages[1],getImages[2]]);
                  break;
                  case 1:
                    setImages([getImages[0], data.data.data.filePath,getImages[2]]);
                  break;
                  case 2:
                    setImages([getImages[0],getImages[1],data.data.data.filePath]);
                break;
              
              }
              setDetails({...details,images:getImages});

              
              handleClose();
  
          } catch (error) {
              console.log(error)
              
          }
      }

      const onChange = (e,ImageName) => {
        handleShow()
        console.log('name',ImageName)
        setFile(ImageName);
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
          files = e.dataTransfer.files;
        } else if (e.target) {
          files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
          setImage(reader.result);
        };
        reader.readAsDataURL(files[0]);
      };

    const getCropData = () => {
        if (typeof cropperRef.current?.cropper !== "undefined") {

            const cropper = cropperRef.current.cropper;
            cropper.getCroppedCanvas().toBlob((blob) => {
                // Handle the Blob as needed
                console.log('Cropped Blob:', blob);
                setBlob(blob);
                if(blob.size/1048576 > 1){
                    alert('Please Crop more file size is Too big');
                }
                // You can also create a URL for the Blob to display the cropped image
                const croppedImageUrl = URL.createObjectURL(blob);
                console.log('Cropped Image URL:', croppedImageUrl);
              },'image/jpeg',0.7);
            // console.log('cropperRef.current?.cropper.getCroppedCanvas()',cropperRef.current?.cropper.getCroppedCanvas().toB)
          setCropData(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
        }
      };
   

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setDetails({ ...details, [name]: value })
    };

    const submitData = async (event) => {
        // setDetails({...details,images:getImages});

        

        try {
            event.preventDefault();
            // if(details.restaurantId==""){
            //     delete details.restaurantId    
            // }
            let AllDetails = {...details};

            delete AllDetails.isBestChoice;
            delete AllDetails.isHotPromotion ; 
            delete AllDetails.isOfferOfTheDay;
            
            let AllUpdatedImages = getImages
            AllDetails.images = AllUpdatedImages;
             if(AllDetails.restaurantId==""){
                delete AllDetails.restaurantId    
            }


            console.log('AllDetails',AllDetails);
            console.log('getImages',getImages);

            const datt = await update_advertising(AllDetails, params.id)
            console.log('response',datt)
            if(datt && datt.data.restaurantId=="" || datt.data.restaurantId == undefined){
                navigate("/dashboard")
            }else{
                navigate("/Restaurants_view/"+datt.data.restaurantId)
            }
        } catch (error) {
            console.log(error)
        }

    }




    const get_Cat_Data = async () => {
        try {
            let data = await getCatData()
            setCatData(data.data.data.data,)
        } catch (error) {
            console.log(error)
        }

    }  

    const food_cat = (event) => {
        const { name, value } = event.target;
        setDetails({...details,[name]:[value]});
        setShowcat(value)

    };
   

    const discount = (event) => {
        const { name, value } = event.target;
        const newDiscount = {
            type: 'percentage',
            value: value
        };
        setDetails({ ...details, [name]: value, discount: newDiscount });
        setDis(value)
    };
    return (
        <>
            <div className="appview">
                {/* sidebar */}
                <Sidebar />
                {/* \ sidebar */}
                {/* right Content */}
                <div className="right_content" >
                    {/* Navbar */}
                    <Navbar />
                    {/* \ Navbar */}
                    {/* deshboad */}

                    {loading ? (
                        <Loader backdrop content="Loading..." vertical size="lg" />
                    ) : (
                        <>
                            <section className="wrapper">
                                <div className="container-fluid">
                                    <div className="wrapper_header d-flex justify-content-between align-items-center">
                                        <div>
                                            <h2 className="comp_heading">Edit Details</h2>
                                            <span className="back_route">
                                            <Link to='/dashboard' >Advertising Management /</Link>

                                                Edit Details
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="wrapper">
                                <div className="container-fluid">
                                    <div className="row justify-content-center">
                                        <div className="col-md-8">
                                            <div className="profile_page">

                                                <form onSubmit={submitData} >
                                                    <label> Image </label>
                                                    <div className="row g-3">

                                                    {getImages.map((value,index) =>
                                                    <div className="col-md-4" key={index}>
                                                        <div className="banner_upload">
                                                            <label htmlFor={`im_file${index}`}>
                                                                {" "}
                                                                <img src={value} alt="" />
                                                                <i className="fa-solid fa-camera" />
                                                            </label>
                                                            <input type="file" multiple accept="image/*" onChange={(e) => onChange(e,index)} className="d-none" id={`im_file${index}`} />
                                                        </div>
                                                    </div>
                                                )}
                                                        {/* {details?.images?.map((imagedata, i) =>
                                                            <div className="col-md-4" >
                                                                <div className="banner_upload" >
                                                                    <label htmlFor="im_file4">
                                                                        {" "}
                                                                        <img src={imagedata ? imagedata : "/assets/images/banner.png"} alt="" />
                                                                        <i className="fa-solid fa-camera" />
                                                                    </label>
                                                                    <input type="file" onChange={(e) => imguplaod(e, i)} className="d-none" id="im_file4" />
                                                                </div>
                                                            </div>

                                                        )} */}
                                                        {/* <div className="col-md-4">
                                                        </div> */}

                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="fname1">Start Date</label>
                                                                <input
                                                                    type="date"
                                                                    name="startDate"
                                                                    className="form-control"
                                                                    defaultValue={new Date(details?.startDate).toJSON()?.slice(0, 10)}
                                                                    onChange={handleInputChange}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="lname">End Date</label>
                                                                <input
                                                                    type="date"
                                                                    name="endDate"
                                                                    className="form-control"
                                                                    defaultValue={new Date(details?.endDate).toJSON()?.slice(0, 10)}
                                                                    onChange={handleInputChange}

                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <label htmlFor="lname">Promotion Name</label>
                                                                <input
                                                                    type="text"
                                                                    name="name"
                                                                    id="lname"
                                                                    defaultValue={details?.name}
                                                                    className="form-control"
                                                                    placeholder="Enter"
                                                                    onChange={handleInputChange}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <label htmlFor="num">Promotion Details</label>
                                                                <textarea
                                                                    name="description"
                                                                    id=""
                                                                    className="form-control"
                                                                    rows={4}
                                                                    placeholder="Type.."
                                                                    defaultValue={details?.description}
                                                                    onChange={handleInputChange}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="gender">Food Categories</label>
                                                                <select name="food_category" value={showcat}  id="" className="form-control" onChange={food_cat}>
                                                                    {catData?.map((e) =>
                                                                        <option key={e._id} value={e?.name}>{e?.name}</option>
                                                                    )}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="gender">Promotion Type</label>

                                                                <select name="discount" value={dis}  className="form-control" onChange={discount}>
                                                                    <option value="5" >5%</option>
                                                                    <option value="10">10%</option>
                                                                    <option value="15">15%</option>
                                                                    <option value="20">20%</option>
                                                                    <option value="25">25%</option>
                                                                    <option value="30">30%</option>
                                                                    <option value="35">35%</option>
                                                                    <option value="40">40%</option>
                                                                    <option value="45">45%</option>
                                                                    <option value="50">50%</option>
                                                                    <option value="1 for 1 ">1 for 1 </option>
                                                                    <option value="Bundle">Bundle </option>
                                                                    <option value="2nd pax 50% off">2nd pax 50% off </option>
                                                                    <option value="Free Meal for 4th or  5th pax">Free Meal for 4th or  5th pax</option>
                                                                    <option value="Special discount" >Special discount</option>
                                                                    {/* <option value={details?.discount?.value}>{details?.discount?.value}%</option> */}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        {/* <div class="col-md-4">
                                      <div class="form-group">
                                          <label for="add">Validity</label>
                                          <input type="text" name="add" id="add" class="form-control"
                                              placeholder="Enter" />
                                      </div>
                                  </div> */}
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <label htmlFor="num">Terms and Conditions</label>
                                                                <textarea
                                                                    name="termsAndConditions"
                                                                    id=""
                                                                    className="form-control"
                                                                    rows={4}
                                                                    placeholder="Type.."
                                                                    defaultValue={details?.termsAndConditions}
                                                                    onChange={handleInputChange}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-12 pt-3">
                                                            <button
                                                                type="submit"
                                                                className="ed_pbtn btn ms-auto"
                                                                aria-label="Close"
                                                            >
                                                                Save
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <Modal  size="lg" show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                            <Modal.Title> Crop Image</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className='row' >
                                    <div className='col-6 ' >
                                    <Cropper
                                                                        ref={cropperRef}
                                                                        style={{ height: 400, width: "100%" }}
                                                                        zoomTo={0}
                                                                        aspectRatio={(getFile == 'LogoImage') ? 1/1 : 1/1}
                                                                        preview=".img-preview"
                                                                        src={image}
                                                                        className='border border-dark'
                                                                        viewMode={1}
                                                                        minCropBoxHeight={10}
                                                                        minCropBoxWidth={10}
                                                                        background={false}
                                                                        responsive={true}
                                                                        autoCropArea={1}
                                                                        checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                                                        guides={true}
                                                                        />
                                                                    

                                    </div>
                                    <div className='col-6  ' >
                                    <h5>Preview:</h5>
                                    {(cropData == '#') ? 'Please Crop Image' :  <img className=' border border-dark' style={{ width: "100%" }} src={cropData} alt="cropped" /> }
                                    </div>
                                    <div className='col-12 mt-3' >
                                        <center>
                                        <Button style={{float:'center'}} variant="primary" onClick={getCropData}>Crop Image</Button>
                                        </center>
                                    </div>
                
                                </div>

                                                                


                            </Modal.Body>
                            <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button  disabled={(cropData == '#')} variant="primary" onClick={imguplaod}>
                                Save Changes
                            </Button>
                            </Modal.Footer>
                        </Modal>

                        </>
                    )}




                    {/* \ deshboad */}
                </div>
                {/* \ right Content */}
            </div>

        </>
    )
}
