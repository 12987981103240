import React, { useEffect, useState } from 'react'
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import Sidebar from '../../layouts/Sidebar'
import Navbar from '../../layouts/Navbar'
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'
import { restorentData, updateRestorentStatus } from '../../services/AuthApi'
import { Loader } from 'rsuite';

export default function Restaurants() {
  let [data, setData] = useState()
  const [loading, setLoading] = useState(true);
  let token = localStorage.getItem("Token");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totaldata, setTotaldata] = useState(0);
  const [limit, setLimit] = useState(10);
  const [nhit, setNhit] = useState(0);
  const [filtername, setFiltername] = useState()


  const headers = {
    'Authorization': `Bearer ${token}`
  };

  useEffect(() => {
    getCouponData(`pageNo=${currentPage}&limit=${limit}`)
  }, []);

  const getCouponData = async (da) => {
    try {
      const data = await restorentData(da)
      setData(data.data.data)
      setCurrentPage(data.data.pageNo)
      setTotalPages(data.data.totalPage)
      setLimit(data.data.limit)
      setTotaldata(data.data.total)
      setNhit(data.data.nextHit)
      setLoading(false);
    } catch (error) {
      toast.error("internal server error")
    }
  }

  const handleNextPage = () => {
    getCouponData(`pageNo=${nhit}&limit=${limit}`)
  };
  const filterData = (e, name) => {
    setFiltername(e.target.textContent)
    getCouponData(`pageNo=${currentPage}&limit=${limit}&sortBy=${name}&sortOrder=1`)
  }

  const StatusData = (e, name) => {
    setFiltername(e.target.textContent)
    getCouponData(`pageNo=${currentPage}&limit=${limit}&status=${name}&sortOrder=1`)
  }




  const chanegStatus = async (id) => {
    await updateRestorentStatus(id).then(res => {
      getCouponData(`pageNo=${currentPage}&limit=${limit}`)
      toast.success(res.message)
    }).catch(error => {
      toast.error(error.response.data.message)
    })

  }

  const deleteResturants = async (id) => {
    try {
      const requestDelete = {
        method: 'DELETE',
        url: `${process.env.REACT_APP_API_URL}api/admin/restaurants/${id}`,
        headers: headers,
      };
      Swal.fire({
        title: 'Are you sure?',
        text: "Are you sure want to Delete this manager Id?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        // console.log(result,'==============')
        if (result.isConfirmed) {
          axios(requestDelete)
            .then(response => {
              getCouponData()
              Swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
              )
            })
            .catch(error => {
              toast.error(error.response.data.message)
            })
        }
      })
    } catch (error) {
      toast.error("internal server error")
    }
  }

  return (
    <>
      <ToastContainer />
      <div className="appview">
        {/* sidebar */}
        <Sidebar />
        {/* \ sidebar */}
        {/* right Content */}
        <div className="right_content">
          {/* Navbar */}
          <Navbar />
          {/* \ Navbar */}
          {/* deshboad */}

          {loading ? (
            <Loader backdrop content="Loading..." vertical size="lg" />
          ) : (
            <>
              <section className="wrapper">
                <div className="container-fluid">
                  <div className="wrapper_header d-flex justify-content-between align-items-center">
                    <div>
                      <h2 className="comp_heading">Restaurant</h2>
                      <h4 className="comp_sub_heading">
                        Here is your general Restaurants list data
                      </h4>
                    </div>
                    <div className="btn-group fiter">
                      <button
                        type="button"
                        className="fiter_btn"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i className="fa-solid fa-sliders me-3 filter_icon" /> {filtername ? filtername : "Filter"}
                        <i className="fa-solid fa-angle-down ms-auto" />
                      </button>
                      <ul className="dropdown-menu dropdown-menu-end">
                        <li>

                          <Link className="dropdown-item" onClick={(e) => filterData(e, "restaurantName")}>
                            Restaurant Name
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" onClick={(e) => filterData(e, "fullName")}  >
                            Customer Name
                          </Link>
                        </li>
                        <li>
                        </li>
                        <li>
                        </li>
                        <li>
                          <Link className="dropdown-item" onClick={(e) => StatusData(e, "ACTIVE")} >
                            Approved
                          </Link>
                          <Link className="dropdown-item" onClick={(e) => StatusData(e, "INACTIVE")} >
                            Rejected
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </section>
              <section className="wrapper">
                <div className="container-fluid">
                  <div className="responsive-table">
                    <table className="admin_table table">
                      <thead className="table_header">
                        <tr>
                          <th>Customer ID</th>
                          <th>
                            <div className="d-flex align-items-end justify-content-start">
                              Restaurant name
                              <span className="d-inline-flex flex-column up_down_icon">
                                <i className="fa-solid fa-caret-up" />
                                <i className="fa-solid fa-caret-down" />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex align-items-end justify-content-start">
                              Customer Name
                              <span className="d-inline-flex flex-column up_down_icon">
                                <i className="fa-solid fa-caret-up" />
                                <i className="fa-solid fa-caret-down" />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex align-items-end justify-content-start">
                              Total Spent
                              <span className="d-inline-flex flex-column up_down_icon">
                                <i className="fa-solid fa-caret-up" />
                                <i className="fa-solid fa-caret-down" />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex align-items-end justify-content-start">
                              Registration
                              <span className="d-inline-flex flex-column up_down_icon">
                                <i className="fa-solid fa-caret-up" />
                                <i className="fa-solid fa-caret-down" />
                              </span>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="table_body">

                        {data ?
                          data?.map((list) =>
                            <tr key={list?._id}>
                              <td data-label="Customer ID">{list?._id}</td>
                              <td data-label="Restaurant name">{list?.restaurantName}</td>
                              <td data-label="Customer Name">{list?.customerName} </td>
                              <td data-label="Total Spent">$0.00</td>
                              <td data-label="Membership">

                                {list.status === "ACTIVE" ?
                                  <Link onClick={() => chanegStatus(list?._id)} className="t_btn acc"> Approved</Link>
                                  :
                                  <Link onClick={() => chanegStatus(list?._id)} className='t_btn'>Rejected </Link>
                                }
                                <div className="btn-group t_dropdown">
                                  <i
                                    className="fa-solid fa-ellipsis"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  />
                                  <ul className="dropdown-menu dropdown-menu-end">
                                    <li>
                                      <Link className="dropdown-item" to={`/Restaurants_view/${list?._id}`}> View Detail</Link>
                                    </li>
                                    <li>
                                      <button
                                        className="dropdown-item"
                                        onClick={() => deleteResturants(list?._id)}
                                      >
                                        Delete
                                      </button>
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>

                          ) : <span>No Data found</span>}
                      </tbody>
                    </table>
                    <div className="table_footer">
                      <div className="row align-items-center g-3">
                        <div className="col-md-6 text-md-start text-center">
                          <p>Showing {currentPage} from {totaldata} data</p>
                        </div>
                        <div className="col-md-6">
                          <nav aria-label="Page navigation example">
                            <ul className="pagination ms-auto me-md-0 me-auto">
                              <li className="page-item">
                                <Link className="page-link" onClick={() => handleNextPage(0)} >
                                  <span aria-hidden="true">
                                    <i className="fa-solid fa-angles-left" />
                                  </span>
                                </Link>
                              </li>
                              {[...Array(totalPages)].map((e, i) =>
                                <li className="page-item" key={i}>
                                  <Link className={currentPage == i + 1 ? "page-link active" : "page-link"}>
                                    {i + 1}
                                  </Link>
                                </li>
                              )}
                              <li className="page-item">
                                <Link className="page-link" onClick={() => handleNextPage()} >
                                  <span aria-hidden="true">
                                    <i className="fa-solid fa-angles-right" />
                                  </span>
                                </Link>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/* modal */}
            </>
          )}


          <div
            className="modal fade"
            id="manager-delete"
            tabIndex={-1}
            aria-labelledby="manager-deleteLabel"
            aria-hidden="true"
          >

          </div>
          {/* \ deshboad */}
        </div>
        {/* \ right Content */}
      </div>

    </>



  )
}
