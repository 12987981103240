import axios from "axios";
import {  toast } from 'react-toastify';
const url = `${process.env.REACT_APP_API_URL}api`
const token = localStorage.getItem("Token");
const headers = {
  'Authorization': `Bearer ${token}`
  //  username = 'food_app';
  //  password = 'food@123';
};

const username = 'food_app';
  const password = 'food@123';
  const credentials = btoa(`${username}:${password}`);
const basicAuth ={
  'Authorization': `Basic ${credentials}`
}

export const edit_advertising = async (id) => {
  const requestOptions = {
    method: 'GET',
    url: `${url}/admin/promotions/${id}`,
    headers: headers,
  };
  return axios(requestOptions).then((response) => {
    if (response.data.statusCode === 200) {
      return response.data
    } else {
      return response
    }
  }).catch((error) => {
    return error.response
  })
}

export const edit_restaurants = async (id) => {
  const requestOptions = {
    method: 'GET',
    url: `${url}/admin/restaurants/${id}`,
    headers: headers,
  };
  return axios(requestOptions).then((response) => {
    if (response.data.statusCode === 200) {
      return response.data
    } else {
      return response
    }
  }).catch((error) => {
    return error.response
  })
}

export const edit_user = async (id) => {
  const requestOptions = {
    method: 'GET',
    url: `${url}/admin/users/${id}`,
    headers: headers,
  };
  return axios(requestOptions).then((response) => {
    if (response.data.statusCode === 200) {
      return response.data
    } else {
      return response
    }
  }).catch((error) => {
    return error.response
  })
}

export const changeStatus = async (id) => {
  const requestOptions = {
    method: 'PATCH',
    url: `${url}/admin/users/${id}`,
    headers: headers,
  };
  return axios(requestOptions).then((response) => {
    if (response.data.statusCode === 200) {
      // console.log(response.data)
      return response.data
    } else {
      // console.log(response)
      return response
    }
  }).catch((error) => {
    return error.response
  })
}

export const restorentData = async (pagination) => {
  const requestOptions = {
    method: 'GET',
    url: `${url}/admin/restaurants?${pagination}`,
    headers: headers,
  };
  return axios(requestOptions).then((response) => {
    if (response.data.statusCode === 200) {
      // console.log(response.data)
      return response.data
    } else {
      // console.log(response)
      return response
    }
  }).catch((error) => {
    // toast.error("internal server error")
    return error.response
  })
}

export const delete_Resturants = async (id) => {
  const requestOptions = {
    method: 'DELETE',
    url: `${url}/admin/restaurants/${id}`,
    headers: headers,
  };
  return axios(requestOptions).then((response) => {
    if (response.data.statusCode === 200) {
      return response.data
    } else {
      return response
    }
  }).catch((error) => {
    return error.response
  })
}

export const updateRestorentStatus = async (id) => {
  const requestOptions = {
    method: 'PATCH',
    url: `${url}/admin/restaurants/${id}`,
    headers: headers,
  };
  return axios(requestOptions).then((response) => {
    if (response.data.statusCode === 200) {
      return response.data
    } else {
      return response
    }
  }).catch((error) => {
    return error.response
  })
}

export const fileUpload = async (data) => {
  const requestOptions = {
    method: 'POST',
    url: `${url}/file/upload`,
    headers: headers,
    data:data
  };
  return axios(requestOptions).then((response) => {
    if (response.data.statusCode === 200) {
      return response.data
    } else {
      return response
    }
  }).catch((error) => {
    return error.response
  })
}

export const update_advertising = async (data,id) => {
  const requestOptions = {
    method: 'PATCH',
    url: `${url}/admin/promotions/${id}`,
    headers: headers,
    data:data
  };
  return axios(requestOptions).then((response) => {
    if (response.data.statusCode === 200) {
      return response.data
    } else {
      return response
    }
  }).catch((error) => {
    toast.error(error.response.data.message)
    return error.response
  })
}

export const update_Restaurent = async (data,id) => {
  const requestOptions = {
    method: 'PUT',
    url: `${url}/admin/restaurants/${id}`,
    headers: headers,
    data:data
  };
  return axios(requestOptions).then((response) => {
    if (response.data.statusCode === 200) {
      return response.data
    } else {
      return response
    }
  }).catch((error) => {
    return error.response
  })
}

export const updateUser = async (data,id) => {
  const requestOptions = {
    method: 'PUT',
    url: `${url}/admin/users/${id}`,
    headers: headers,
    data:data
  };
  return axios(requestOptions).then((response) => {
    if (response.data.statusCode === 200) {
      return response.data
    } else {
      return response
    }
  }).catch((error) => {
    return error.response
  })
}


export const Add_promotions = async (data) => {
  const requestOptions = {
    method: 'POST',
    url: `${url}/admin/promotions`,
    headers: headers,
    data:data
  };
  return axios(requestOptions).then((response) => {
    if (response.data.statusCode === 201) {
      return response.data
    } else {
      return response
    }
  }).catch((error) => {
    return error.response
  })
}

export const Add_coupon = async (data) => {
  const requestOptions = {
    method: 'POST',
    url: `${url}/coupon`,
    headers: headers,
    data:data
  };
  console.log(data,'========')
  return axios(requestOptions).then((response) => {
    console.log(response,'=========')
    if (response.data.statusCode === 201) {
      return response.data
    } else {
      return response
    }
  }).catch((error) => {
    return error.response
  })
}

export const edit_coupon = async (id) => {
  const requestOptions = {
    method: 'GET',
    url: `${url}/coupon/${id}`,
    headers: headers,
  };
  return axios(requestOptions).then((response) => {
    if (response.data.statusCode === 200) {
      return response.data
    } else {
      return response
    }
  }).catch((error) => {
    return error.response
  })
}
export const update_coupon = async (data,id) => {
  const requestOptions = {
    method: 'PATCH',
    url: `${url}/coupon/${id}`,
    headers: headers,
    data:data
  };
  return axios(requestOptions).then((response) => {
    if (response.data.statusCode === 200) {
      return response.data
    } else {
      return response
    }
  }).catch((error) => {
    return error.response
  })
}

export const getCatData = async () => {
  const requestOptions = {
    method: 'GET',
    url: `${url}/category/listing?pageNo=1&limit=30`,
    headers: basicAuth,
  };
  return axios(requestOptions).then((response) => {
    if (response.data.statusCode === 201) {
      return response.data.data
    } else {
      return response
    }
  }).catch((error) => {
    return error.response
  })
}

export const GetDashPromotions = async (pages) => {
  const requestOptions = {
    method: 'GET',
    url: `${url}/admin/promotions?${pages}`,
    headers: headers,
  };
  return axios(requestOptions).then((response) => {
    if (response.data.statusCode === 200) {
      return response.data
    } else {
      return response
    }
  }).catch((error) => {
    return error.response
  })
}


export const GetUser = async (pages) => {
  const requestOptions = {
    method: 'GET',
    url: `${url}/admin/users?${pages}`,
    headers: headers,
  };
  return axios(requestOptions).then((response) => {
    if (response.data.statusCode === 200) {
      return response.data
    } else {
      return response
    }
  }).catch((error) => {
    return error.response
  })
}




export const GetPromotions = async (id) => {
  const requestOptions = {
    method: 'GET',
    url: `${url}/admin/restaurants/restaurant_promotions?restaurantId=${id}&pageNo=1&limit=10`,
    headers: headers,

  };
  return axios(requestOptions).then((response) => {
    if (response.data.statusCode === 200) {
      return response.data
    } else {
      return response
    }
  }).catch((error) => {
    return error.response
  })
}


export const GetRestaurants = async (id) => {
  const requestOptions = {
    method: 'GET',
    url: `${url}/admin/restaurants/${id}`,
    headers: headers,
  };
  return axios(requestOptions).then((response) => {
    if (response.data.statusCode === 200) {
      return response.data.data
    } else {
      return response
    }
  }).catch((error) => {
    return error.response
  })
}


// Vijay Start  Edit's
// For Notifaction 



export const getAdminNotification = async () => {
  const requestOptions = {
    method: 'GET',
    url: `${url}/admin/notifications`,
    headers: headers,
    params:{
      pageNo:1,
      limit:10
    }
  };
  return axios(requestOptions).then((response) => {
    if (response.data.statusCode === 200) {
      return response.data.data
    } else {
      return response
    }
  }).catch((error) => {
    return error.response
  })
}



export const getAdminReadNotification = async () => {
  const requestOptions = {
    method: 'POST',
    url: `${url}/admin/notifications/read`,
    headers: headers,
  };
  return axios(requestOptions).then((response) => {
    if (response.data.statusCode === 200) {
      return response.data.data
    } else {
      return response
    }
  }).catch((error) => {
    return error.response
  })
}

export const getNotification = async (id) => {
  const requestOptions = {
    method: 'GET',
    url: `${url}/user/notifications/${id}`,
    headers: headers,
  };
  return axios(requestOptions).then((response) => {
    if (response.data.statusCode === 200) {
      return response.data.data
    } else {
      return response
    }
  }).catch((error) => {
    return error.response
  })
}






// Vijay End Edit's


export const logout = async (id) => {
  const requestOptions = {
    method: 'GET',
    url: `${url}/auth/logout`,
    headers: headers,
  };
  return axios(requestOptions).then((response) => {
    if (response.data.statusCode === 200) {
      return response.data.data
    } else {
      return response
    }
  }).catch((error) => {
    return error.response
  })
}


// auth/logout