import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios";
import Sidebar from '../../layouts/Sidebar'
import Navbar from '../../layouts/Navbar'
import { Link, useParams } from 'react-router-dom';
import { Loader } from 'rsuite';

export default function ViewCoupon() {
    const params = useParams();
    const [details, setDetails] = useState()
    const [loading, setLoading] = useState(true);
    let token = localStorage.getItem("Token");
    const headers = {
        'Authorization': `Bearer ${token}`
    };

    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}api/coupon/${params.id}`,
        headers: headers,
    };
    useEffect(() => {
        getDashboardData()
    }, []);

    const getDashboardData = async () => {
        try {
            axios(requestOptions)
                .then(response => {
                    setDetails(response.data.data)
                    setLoading(false);
                })
                .catch(error => {
                    toast.error(error.response.data.message)
                })
        } catch (error) {
            
            toast.error("internal server error")
        }
    }   

    return (
        <div className="appview">
            <ToastContainer />
            {/* sidebar */}
            <Sidebar />
            {/* \ sidebar */}
            {/* right Content */}
            <div className="right_content">
                {/* Navbar */}
                <Navbar />
                {/* \ Navbar */}
                {/* deshboad */}
                {loading ? (
                    <Loader backdrop content="Loading..." vertical size="lg" />
                ) : (
                    <>
                        {/* deshboad */}
                        <section className="wrapper">
                            <div className="container-fluid">
                                <div className="wrapper_header d-flex justify-content-between align-items-center">
                                    <div>
                                        <h2 className="comp_heading">View Coupons</h2>
                                        <span className="back_route">
                                        <Link to='/coupons' >Coupons /</Link>
                                            View Coupons
                                        </span>
                                    </div>

                                <Link className="ico_box active " to={`/editCoupon/${details?._id}`} > <i className="fa-solid fa-edit" /> </Link>
                                </div>
                            </div>
                        </section>
                        <section className="wrapper">
                            <div className="container-fluid">
                                <div className="view_coupon">
                                    <div className="banner">
                                        <img className="w-100" src={details.image ? details.image : "/assets/images/banner.png"} alt="" />
                                    </div>
                                    <div className="banner">
                                    <h4>Logo</h4>
                                        <img style={{ width: "200px", height: "200px" }}  className="w-10" src={details.logo ? details.logo : "/assets/images/banner.png"} alt="" />
                                    </div>
                                    <div className="card-body view_del_coup">
                                        <div className="d-flex justify-content-start align-items-center">
                                            <h3>Serial No.:</h3>
                                            <span>{details?.serialNo}</span> 
                                        </div>
                                        <div className="d-flex justify-content-start align-items-center">
                                             <h3>  Max Coupon.:</h3>
                                            <span>{details?.maxUses}, </span>  <span> </span> <h3>Coupon Use.:</h3>
                                            <span>{details?.usedCount}</span>
                                        </div>
                                        <div className="d-flex justify-content-start align-items-center">
                                            <h3>Valid From.:</h3>
                                            <span>{details?.validFrom}</span> <span> </span>
                                            <h3>Valid To.:</h3>
                                            <span>{details?.validUntil}</span> 
                                        </div>
                                        <div className="d-flex justify-content-start align-items-center">
                                             <h3> Coupon:</h3>
                                            {/* <span>{details?.maxUses} </span>  */}
                                            <span> {new Date(details?.validUntil) >= new Date()? <span className='btn btn-outline-success '>Active</span>  : <span className='btn btn-outline-danger' >Expire</span>  } </span>
                                        </div>
                                        <h3>{details?.discount} % Discount for {details?.title}</h3>
                                        <p>
                                            {details?.description}
                                            {/* Lorem ipsum dolor sit amet consectetur, adipisicing elit. Obcaecati
                  accusamus pariatur adipisci reiciendis veniam. Accusantium amet
                  voluptate at expedita, quos, ipsam recusandae explicabo perferendis
                  et placeat numquam architecto quis nihil quisquam labore voluptatem!
                  Nihil obcaecati, reprehenderit necessitatibus velit inventore
                  excepturi dolores quibusdam ipsam expedita unde eos molestiae autem
                  nemo ...<a href="">See More...</a> */}
                                        </p>
                                        <hr />
                                        <h3>Terms and Conditions</h3>
                                        <p>
                                            {details?.termsAndConditions}
                                            {/* Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quisquam
                  neque dignissimos maiores quae quaerat quos accusantium odio nulla
                  ratione ex, repudiandae error atque odit ab corrupti magni nostrum
                  totam nihil aspernatur temporibus eos autem. Architecto inventore
                  perspiciatis, officiis doloribus vero voluptates! Cupiditate ab
                  ullam deleniti amet ducimus delectus magnam aliquid. */}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </>

                )}



                {/* \ deshboad */}
            </div>
            {/* \ right Content */}
        </div>

    )
}
