import React, { useEffect, useState,createRef } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import Sidebar from '../../layouts/Sidebar'
import Navbar from '../../layouts/Navbar'
import { fileUpload, Add_promotions, getCatData } from '../../services/AuthApi'
import { useSearchParams,useNavigate,Link } from 'react-router-dom';
import DemoImage from './../../images/banner.png';
import Modal from 'react-bootstrap/Modal';
import Cropper, { ReactCropperElement } from "react-cropper";
import Button from 'react-bootstrap/Button';
import "cropperjs/dist/cropper.css";
const defaultSrc ="https://raw.githubusercontent.com/roadmanfong/react-cropper/master/example/img/child.jpg";

export default function AddPromotation() {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate()
    const [catData, setCatData] = useState()
    const [details, setDetails] = useState({});
    const [getImages, setImages] = useState([DemoImage,DemoImage,DemoImage])
    const [show, setShow] = useState(false);
    const [getFile, setFile] = useState();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [image, setImage] = useState(defaultSrc);
    const [myBlob, setBlob] = useState('');
    const [cropData, setCropData] = useState("#")
    const cropperRef = createRef();




    // const imguplaod1 = async (data1) => {
    //     let uploadedFilePaths = [];
    //     for (let i = 0; i < data1.target.files.length; i++) {
    //         const imgd = data1.target.files[i]
    //         let formdata = new FormData();
    //         formdata.append("file", imgd);
    //         let data = await fileUpload(formdata);
    //         uploadedFilePaths.push(data.data.data.filePath);
    //     }
    //     // setDetails(prevData => {
    //     //     return { ...prevData, images: uploadedFilePaths };
    //     // });

    // }

    const imguplaod = async () => {
    
        await  getCropData();
          try {
              let formData = new FormData();
              formData.append("file", myBlob,"myfile.jpeg");
              let data = await fileUpload(formData);
            //   console.log(getFile,data.data.data.filePath)
              setCropData('#');
              switch (getFile) {
                  case 0:
                    setImages( [data.data.data.filePath,getImages[1],getImages[2]]);
                  break;
                  case 1:
                    setImages([getImages[0], data.data.data.filePath,getImages[2]]);
                  break;
                  case 2:
                    console.log(getFile,data.data.data.filePath)
                    setImages([getImages[0],getImages[1],data.data.data.filePath]);
                  break;
              }
              console.log(getFile,getImages)
           

              setDetails({...details,images:getImages});

              
              handleClose();
  
          } catch (error) {
              console.log(error)
              
          }
      }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setDetails({ ...details, [name]: value })
    };

    const onChange = (e,ImageName) => {
        handleShow()
        console.log('name',ImageName)
        setFile(ImageName);
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
          files = e.dataTransfer.files;
        } else if (e.target) {
          files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
          setImage(reader.result);
        };
        reader.readAsDataURL(files[0]);
      };

    const getCropData = () => {
        if (typeof cropperRef.current?.cropper !== "undefined") {

            const cropper = cropperRef.current.cropper;
            cropper.getCroppedCanvas().toBlob((blob) => {

                // Handle the Blob as needed
                console.log('Cropped Blob:', blob);
                console.log('Cropped Blob Size:', blob.size);
                console.log('In Mb ',blob.size/1048576);
                if(blob.size/1048576 > 1){
                    alert('Please Crop more file size is Too big');
                }
                setBlob(blob);
                // You can also create a URL for the Blob to display the cropped image
                const croppedImageUrl = URL.createObjectURL(blob);
                console.log('Cropped Image URL:', croppedImageUrl);

              },'image/jpeg',0.7);
            // console.log('cropperRef.current?.cropper.getCroppedCanvas()',cropperRef.current?.cropper.getCroppedCanvas().toB)
          setCropData(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
        }
      };



   
    useEffect(() => {
        get_Data()
        if(searchParams.get("id")){
            setDetails({restaurantId:searchParams.get("id")})
        }
    }, [])

    const get_Data = async () => {
        try {
            let data = await getCatData()
            setCatData(data.data.data.data,)
        } catch (error) {
            console.log(error)
        }

    }


    // console.log(catData,)
    const submitData = async (event) => {
        setDetails({...details,images:getImages});
        try {
            event.preventDefault();
         
            let AllDetails = {...details};
            let AllUpdatedImages = getImages
            AllDetails.images = AllUpdatedImages;
           

            console.log('AllDetails',AllDetails);
            console.log('getImages',getImages);
            console.log('details',details)
            const datt = await Add_promotions(AllDetails)
            console.log(datt)
            if ( datt && datt.statusCode === 201) {
                toast.success("Promition add successfully")
                setTimeout(() => {
                    if(searchParams.get("id")){
                        navigate("/Restaurants_view/"+searchParams.get("id"))
                    }else{
                        navigate("/dashboard")
                    }
                }, 1000)

            } else {
                return toast.error(datt.data.message)
            }

        } catch (error) {
            console.log(error)
            toast.error("invalid")
        }

    }

    const discount = (event) => {
        const { name, value } = event.target;
        const newDiscount = {
            type: 'percentage',
            value: value
        };
        setDetails({ ...details, [name]: value, discount: newDiscount });
    };

    const food_cat = (event) => {
        const { name, value } = event.target;
        setDetails({...details,[name]:[value]});
    };

    // console.log(details,'============details')

    return (
        <>
            <ToastContainer />
            <div className="appview">
                {/* sidebar */}
                <Sidebar />
                {/* \ sidebar */}
                {/* right Content */}
                <div className="right_content">
                    {/* Navbar */}
                    <Navbar />
                    {/* \ Navbar */}
                    {/* deshboad */}
                    <section className="wrapper">
                        <div className="container-fluid">
                            <div className="wrapper_header d-flex justify-content-between align-items-center">
                                <div>
                                    <h2 className="comp_heading">Add Details</h2>
                                    <span className="back_route">
                                    <Link to='/dashboard' >Advertising Management /</Link>
                                        Add Promotion
                                    </span>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="wrapper">
                        <div className="container-fluid">
                            <div className="row justify-content-center">
                                <div className="col-md-8">
                                    <div className="profile_page">
                                        <form onSubmit={submitData}>
                                        <label htmlFor="images">Images</label>
                                            <div className="row g-3">


                                                {getImages.map((value,index) =>
                                                    <div className="col-md-4" key={`images-${index}`}>
                                                        <div className="banner_upload">
                                                            <label htmlFor={`im_file${index}`}>
                                                                {" "}
                                                                <img src={value} alt="" />
                                                                <i className="fa-solid fa-camera" />
                                                            </label>
                                                            <input type="file" multiple accept="image/*" onChange={(e) => onChange(e,index)} className="d-none" id={`im_file${index}`} />
                                                        </div>
                                                    </div>
                                                )}

                                           
                                                
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label htmlFor="fname1">Start Date</label>
                                                        <input
                                                            type="date"
                                                            name="startDate"
                                                            id="fname1"

                                                            className="form-control"
                                                            placeholder="Enter"
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label htmlFor="lname">End Date</label>
                                                        <input
                                                            type="date"
                                                            name="endDate"
                                                            id="lname"
                                                            className="form-control"

                                                            placeholder="Enter"
                                                            onChange={handleInputChange}

                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label htmlFor="lname">Promotion Name</label>
                                                        <input
                                                            type="text"
                                                            name="name"
                                                            id="lname"
                                                            className="form-control"
                                                            placeholder="Enter"
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label htmlFor="num">Promotion Details</label>
                                                        <textarea
                                                            name="description"
                                                            id=""
                                                            className="form-control"
                                                            rows={4}
                                                            placeholder="Type.."
                                                            defaultValue={""}
                                                            onChange={handleInputChange}

                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label htmlFor="gender">Food Categories</label>
                                                        <select name="food_category" id="" className="form-control" onChange={food_cat} >
                                                            <option >Select Option</option>
                                                            {catData?.map((e,index) =>
                                                                <option key={`cat-${index}`} value={e?.name}>{e?.name}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label htmlFor="gender">Promotion Type</label>
                                                        <select name="discount" id="" className="form-control" onChange={discount}>
                                                            <option >Select Option</option>
                                                            <option value="5">5%</option>
                                                            <option value="10">10%</option>
                                                            <option value="15">15%</option>
                                                            <option value="20">20%</option>
                                                            <option value="25">25%</option>
                                                            <option value="30">30%</option>
                                                            <option value="35">35%</option>
                                                            <option value="40">40%</option>
                                                            <option value="45">45%</option>
                                                            <option value="50">50%</option>
                                                            <option value="1 for 1 ">1 for 1 </option>
                                                            <option value="Bundle">Bundle </option>
                                                            <option value="2nd pax 50% off">2nd pax 50% off</option>
                                                            <option value="Free Meal for 4th or  5th pax">Free Meal for 4th or  5th pax</option>
                                                            <option value="Special discount" >Special discount</option>

                                                        </select>
                                                    </div>
                                                </div>
                                                {/* <div class="col-md-4">
                                      <div class="form-group">
                                          <label for="add">Validity</label>
                                          <input type="text" name="add" id="add" class="form-control"
                                              placeholder="Enter" />
                                      </div>
                                  </div> */}
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label htmlFor="num">Terms and Conditions</label>
                                                        <textarea
                                                            name="termsAndConditions"
                                                            id=""
                                                            className="form-control"
                                                            rows={4}
                                                            placeholder="Type.."
                                                            defaultValue={""}
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 pt-3">
                                                    <button
                                                        type="submit"
                                                        className="ed_pbtn btn ms-auto"
                                                        data-bs-dismiss="modal"
                                                        aria-label="Close"
                                                    >
                                                        Create
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Modal  size="lg" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title> Crop Image</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row' >
                        <div className='col-6 ' >
                        <Cropper
                                                            ref={cropperRef}
                                                            style={{ height: 400, width: "100%" }}
                                                            zoomTo={0}
                                                            aspectRatio={(getFile == 'LogoImage') ? 1/1 : 1/1}
                                                            preview=".img-preview"
                                                            src={image}
                                                            className='border border-dark'
                                                            viewMode={1}
                                                            minCropBoxHeight={10}
                                                            minCropBoxWidth={10}
                                                            background={false}
                                                            responsive={true}
                                                            autoCropArea={1}
                                                            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                                            guides={true}
                                                            />
                                                         

                        </div>
                        <div className='col-6  ' >
                        <h5>Preview:</h5>
                        {(cropData == '#') ? 'Please Crop Image' :  <img className=' border border-dark' style={{ width: "100%" }} src={cropData} alt="cropped" /> }
                        </div>
                        <div className='col-12 mt-3' >
                            <center>
                            <Button style={{float:'center'}} variant="primary" onClick={getCropData}>Crop Image</Button>
                            </center>
                        </div>
    
                    </div>

                                                    


                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button  disabled={(cropData == '#')} variant="primary" onClick={imguplaod}>
                    Save Changes
                </Button>
                </Modal.Footer>
            </Modal>
                    {/* \ deshboad */}
                </div>
                {/* \ right Content */}
            </div>

        </>
    )
}
