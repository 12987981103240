import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { getAdminNotification } from '../services/AuthApi'
import  LogoImage from "./../images/MonsterFoodz Logo.png"
const Navbar = () => {
  const [data, setData] = useState("")
  const [notification, setnotification] = useState(0)
  const navigate = useNavigate()

  useEffect(() => {
    let Data = localStorage.getItem("Data")
    setData(JSON.parse(Data))
    if (!Data ) {
      localStorage.clear()
      navigate("/")
    }
    fetchNotification();


  }, [])

  const fetchNotification = async () => {

    const response = await getAdminNotification({pageNo:1,limit:10});
    // console.log('response',response)
    setnotification(response);

}


  //========================================getData==========================================================
  return (
    <>
      <nav className="navbar navbar-expand-xl">
        <div className="container-fluid">
          <div
            className="collapse navbar-collapse w-100"
            id="navbarSupportedContent"
          >
            <div className="d-flex flex-lg-nowrap flex-wrap justify-content-between  w-100 align-items-end">
              <div className="nav_search w-100">
                <input
                  type="text"
                  className="form-control mb-lg-0 mb-4"
                  placeholder="Search here"
                />
                <i className="fa-solid fa-magnifying-glass" />
              </div>
              <Link to="/notification" href="" className="nt_box">
                <i className="fa-regular fa-bell" />
                {(notification == '0')  ?  '' :   <span className="num_cir"> {notification.unReadCount}</span>}
              
              </Link>
              {/* <a
                href=""
                className="nt_box"
                style={{ backgroundColor: "rgba(255, 91, 91, 0.15)" }}
              >
                <i className="fa-solid fa-gear" style={{ color: "#FF5B5B" }} />
               
              </a> */}
            </div>
          </div>
          <i
            className="fa-solid fa-angles-down p-2 nav_op collapsed d-xl-none"
            style={{ cursor: "pointer" }}
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          />
          <Link to="/dashboard"  className="nav_user me-auto">
            <h6>
              Hello,<strong> Admin </strong>
            </h6>
            <div className="nav_user_img">
              <img src={LogoImage} alt="" />
            </div>
          </Link>
          <i className="fa-solid fa-bars me-2 side_op_btn  d-xl-none" />
        </div>
      </nav>



    </>
  )
}
export default Navbar;