import React, { useEffect, useState } from 'react'
import Sidebar from '../../layouts/Sidebar'
import Navbar from '../../layouts/Navbar'
import { edit_user,fileUpload,updateUser } from '../../services/AuthApi'
import { useParams ,useNavigate,Link} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Loader } from 'rsuite';


export default function Edit_user() {
  const navigate = useNavigate()
    const params = useParams();
    const [details, setDetails] = useState()
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        get_Data()
    }, []);

    const get_Data = async () => {
        let data = await edit_user(params.id)
        // console.log(data.data,'=================11111111111111111')
        setDetails(data.data)
        setLoading(false);
    }

    const handleInputChange = (event) => {
      const { name, value } = event.target;
      setDetails({ ...details, [name]: value })
  };

  const imguplaod = async (data1, index) => {
    let imgd = data1.target.files[0]
    let formdata = new FormData()
    formdata.append("file", imgd);
    let data = await fileUpload(formdata)
    setDetails({ ...details, profilePicture: data.data.data.filePath })
}

const submitData = async (event) => {
  try {
    event.preventDefault();
    const datt = await updateUser(details, params.id)
  
    if(datt.statusCode==200){
      toast.success(datt.message)
    }else{
      toast.error(datt.message)
    }
 
    navigate("/user_management")
  } catch (error) {
    console.log(error)
  }

}
console.log(details,'===')


  return (
    <>
    <ToastContainer />
    <div className="appview">
  {/* sidebar */}
  <Sidebar />
  {/* \ sidebar */}
  {/* right Content */}
  <div className="right_content">
    {/* Navbar */}
   <Navbar />
    {/* \ Navbar */}
    {/* deshboad */}
    {loading ? (
        <Loader backdrop content="Loading..." vertical size="lg" />
      ) : (
        <>
        <section className="wrapper">
      <div className="container-fluid">
        <div className="wrapper_header d-flex justify-content-between align-items-center">
          <div>
            <h2 className="comp_heading">User ID #{details?._id}</h2>
            <span className="back_route">

              <Link to='/user_management' >User management/ </Link>
              User Edit
            </span>
          </div>
        </div>
      </div>
    </section>
    <section className="wrapper">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="profile_page">
              <div className="modal-header p-0 border-0">
                <h4>Edit User Id</h4>
                <button
                  type="button"
                  className="btn_close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fa-solid fa-xmark" />
                </button>
              </div>
              <div className="modal-body">
                <form onSubmit={submitData}>
                  <div className="row">
                    <div className="col-12">
                      <div className="img_upload">
                        <label htmlFor="im_file4">
                          {" "}
                          <img src={details?.profilePicture ? details?.profilePicture : "/assets/images/user3.png"} alt="" />{" "}
                          <i className="fa-solid fa-camera" />
                        </label>
                        <input type="file" onChange={(e) => imguplaod(e)} className="d-none" id="im_file4" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <lable htmlFor="fname1">First Name</lable>
                        <input
                          type="text"
                          name="firstName"
                          id="fname1"
                          className="form-control"
                          defaultValue={details?.firstName}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <lable htmlFor="lname">Last name</lable>
                        <input
                          type="text"
                          name="lastName"
                          id="lname"
                          className="form-control"
                          defaultValue={details?.lastName}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <lable htmlFor="num">Telephone number</lable>
                        <input
                          type="text"
                          name="phoneNo"
                          id="num"
                          className="form-control"
                          defaultValue={details?.phoneNo}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <lable htmlFor="gender">Gender</lable>
                        <select
                          name="gender"
                          id="gender"
                          className="form-control"
                          onChange={handleInputChange}
                          
                        >
                          <option value="">Male</option>
                          <option value="">Female</option>
                          <option value="">Others</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <lable htmlFor="dob">Date of birth</lable>
                        <input
                          type="date"
                          name="DOB"
                          id="dob"
                          className="form-control"
                          defaultValue={details?.DOB}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <lable htmlFor="add">Address</lable>
                        <input
                          type="text"
                          name="fullAddress"
                          id="add"
                          className="form-control"
                          defaultValue={details?.location?.fullAddress}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-12 pt-3">
                      <button
                        type="submit"
                        className="ed_pbtn btn ms-auto"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
        </>
      )}

    
    {/* \ deshboad */}
  </div>
  {/* \ right Content */}
</div>

    </>
  )
}
