import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios";
import Sidebar from '../../layouts/Sidebar'
import Navbar from '../../layouts/Navbar'
import { useParams,Link } from 'react-router-dom';
import { Loader } from 'rsuite';


export default function User_view() {
    const params = useParams();
    const [details,setDetails] = useState()
    const [loading, setLoading] = useState(true);
    let token = localStorage.getItem("Token");
    const headers = {
      'Authorization': `Bearer ${token}`
    };
  
    const requestOptions = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}api/admin/users/${params.id}`,
      headers: headers,
    };
    useEffect(() => {
      getUserData()
    }, []);
  
    const getUserData = async () => {
      try {
        axios(requestOptions)
          .then(response => {
            // console.log(response.data.data)
            setDetails(response.data.data)
            setLoading(false);
          })
          .catch(error => {
            toast.error(error.response.data.message)
          })
      } catch (error) {
        toast.error("internal server error")
      }
    }


  return (
    <>
    <div className="appview">
  {/* sidebar */}
  <Sidebar />
  {/* \ sidebar */}
  {/* right Content */}
  <div className="right_content">
    {/* Navbar */}
    <Navbar />
    {/* \ Navbar */}
    {/* deshboad */}

    {loading ? (
        <Loader backdrop content="Loading..." vertical size="lg" />
      ) : (
       <>
       <section className="wrapper">
      <div className="container-fluid">
        <div className="wrapper_header d-flex justify-content-between align-items-center">
          <div>
            <h2 className="comp_heading">User ID #{details?._id}</h2>
            <span className="back_route">

              <Link to='/user_management' >User management/ </Link>
              User Detaills
            </span>
          </div>
          {/* <a href="user-id-edit.html" className="btn ed_pbtn">
            Edit <i className="fa-solid fa-chevron-down ms-2" />
          </a> */}
        </div>
      </div>
    </section>
    <section className="wrapper">
      <div className="container-fluid">
        <div className="profile_page">
          <div className="row justify-content-center">
            <div className="col-lg-4 mx-auto">
              <div className="user_profile">

                {details ? <img src={details?.profilePicture} alt="" /> : <img src="/assets/images/user3.png" alt="" /> }
                <h3>{details?.fullName}</h3>
                <h4>{details?.email}</h4>
              </div>
            </div>
            <div className="col-lg-8">
              <form>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <lable htmlFor="fname">First Name</lable>
                      <input
                        type="text"
                        name="fname"
                        id="fname"
                        className="form-control"
                        readOnly
                        defaultValue={details?.firstName}  
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <lable htmlFor="fname">Last name</lable>
                      <input
                        type="text"
                        name="fname"
                        id="fname"
                        className="form-control"
                        readOnly
                        defaultValue={details?.lastName}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <lable htmlFor="fname">Telephone number</lable>
                      <input
                        type="text"
                        name="fname"
                        id="fname"
                        className="form-control"
                        readOnly
                        defaultValue={details?.phoneNo}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <lable htmlFor="fname">Gender</lable>
                      <input
                        type="text"
                        name="fname"
                        id="fname"
                        className="form-control"
                        readOnly
                        defaultValue="M"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <lable htmlFor="fname">Date of birth</lable>
                      <input
                        type="text"
                        name="fname"
                        id="fname"
                        className="form-control"
                        readOnly=""
                        defaultValue={details?.DOB}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <lable htmlFor="fname">Address</lable>
                      <input
                        type="text"
                        name="fname"
                        id="fname"
                        className="form-control"
                        readOnly
                        defaultValue={details?.fullAddress}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* \ deshboad */}
       </>
      )}

    
  </div>
  {/* \ right Content */}
</div>

    </>
  )
}
