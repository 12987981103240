
import React, { useEffect, useState,createRef } from 'react'
import Sidebar from '../../layouts/Sidebar'
import Navbar from '../../layouts/Navbar'
import { edit_coupon, fileUpload, update_coupon } from '../../services/AuthApi'
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Loader } from 'rsuite';
import { toast,ToastContainer } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
const defaultSrc ="https://raw.githubusercontent.com/roadmanfong/react-cropper/master/example/img/child.jpg";



export default function EditCoupon() {



    const [show, setShow] = useState(false);
    const [getFile, setFile] = useState();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

  

    
    
    // Crop Edits ------
    const [image, setImage] = useState(defaultSrc);
    const [myBlob, setBlob] = useState('');
    const [cropData, setCropData] = useState("#")
    const cropperRef = createRef();



    const onChange = (e,ImageName) => {
        handleShow()
        setFile(ImageName);
        setCropData('#')
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
          files = e.dataTransfer.files;
        } else if (e.target) {
          files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
          setImage(reader.result);
        };
        reader.readAsDataURL(files[0]);
      };

      const getCropData = () => {
        if (typeof cropperRef.current?.cropper !== "undefined") {

            const cropper = cropperRef.current.cropper;
            cropper.getCroppedCanvas().toBlob((blob) => {
                // Handle the Blob as needed
                console.log('Cropped Blob:', blob);
                setBlob(blob);
                if(blob.size/1048576 > 1){
                    alert('Please Crop more file size is Too big');
                }
                // You can also create a URL for the Blob to display the cropped image
                const croppedImageUrl = URL.createObjectURL(blob);
                console.log('Cropped Image URL:', croppedImageUrl);
              },'image/jpeg',0.7);
            // console.log('cropperRef.current?.cropper.getCroppedCanvas()',cropperRef.current?.cropper.getCroppedCanvas().toB)
          setCropData(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
        }
      };









    const navigate = useNavigate()
    const params = useParams();
    const [details, setDetails] = useState({})
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        get_Data()
    }, []);

    const get_Data = async () => {
        try {
            let data = await edit_coupon(params.id)
            console.log(data.data, '========')
            setDetails(data.data)
            setLoading(false);
        } catch (error) {
            console.log(error)
        }

    }

    const imguplaod = async () => {
    
        await  getCropData();
  
          try {
          
              let formData = new FormData();
              formData.append("file", myBlob,"myfile.jpeg");
              let data = await fileUpload(formData);
              
  
              switch (getFile) {
                  case 'CoverImage':
                      setDetails({ ...details,image: data.data.data.filePath });
                  break;
                  case 'LogoImage':
                      setDetails({ ...details,logo: data.data.data.filePath });
                  break;
              
              }
              
              // setDetails({ ...details,image: data.data.data.filePath });
              
              handleClose();
  
          } catch (error) {
              console.log(error)
          }
      }
  
    // const imguplaod = async (data1) => {
    //     try {
    //         const file = data1.target.files[0];
    //         let formData = new FormData();
    //         formData.append("file", file);
    //         let data = await fileUpload(formData);
    //         setDetails({ image: data.data.data.filePath });
    //     } catch (error) {
    //         console.log(error)
    //     }

    // }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setDetails({ ...details, [name]: (name && name === "discount" || name === "maxUses") ? parseFloat(value) : value })
    };

    // const submitData = async (event) => {
    //     console.log('===========ss')
    //     try {
    //         // event.preventDefault();
    //         console.log(details, '===============')
    //         const datt = await update_coupon(details, params.id)
    //         console.log(datt, '============')
    //         navigate("/viewCoupon/" + params.id)
    //     } catch (error) {
    //         console.log(error)
    //     }

    // }

    const logoImgUplaod = async (data1) => {
        try {
            const file = data1.target.files[0];
            let formData = new FormData();
            formData.append("file", file);
            let data = await fileUpload(formData);
            setDetails({...details, logo: data.data.data.filePath });
        } catch (error) {
            console.log(error)
        }
    }

    const DataToSave = async () => {
        try {
            console.log(details,'======rrrrr')
            const datt = await update_coupon(details, params.id)
            // console.log(datt,'======')
           if (datt.statusCode === 200) {
                toast.success(datt.message)
                navigate("/viewCoupon/" + params.id)
            } else {
                console.log(datt,"====")
                toast.error(datt.message)
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
        <ToastContainer />
            <div className="appview">
                {/* sidebar */}
                <Sidebar />
                {/* \ sidebar */}
                {/* right Content */}
                <div className="right_content" >
                    {/* Navbar */}
                    <Navbar />
                    {/* \ Navbar */}
                    {/* deshboad */}

                    {loading ? (
                        <Loader backdrop content="Loading..." vertical size="lg" />
                    ) : (
                        <>
                            <section className="wrapper">
                                <div className="container-fluid">
                                    <div className="wrapper_header d-flex justify-content-between align-items-center">
                                        <div>
                                            <h2 className="comp_heading">Edit Coupon Detail</h2>
                                            <span className="back_route">
                                            <Link to='/coupons' >Coupons /</Link>
                                                Edit Coupon Detail
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="wrapper">
                                <div className="container-fluid">
                                    <div className="row justify-content-center">
                                        <div className="col-md-8">
                                            <div className="profile_page">
                                                <form >
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="banner_upload">
                                                                <label htmlFor="im_file4">
                                                                    {" "}
                                                                    <img src={details.image ? details.image : "/assets/images/banner.png"} alt="" />
                                                                    <i className="fa-solid fa-camera" />
                                                                </label>
                                                                <input type="file" className="d-none" id="im_file4"  onChange={(e) => onChange(e,'CoverImage')} />
                                                            </div>
                                                        </div>


                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <lable htmlFor="fname1">Add Coupon Logo</lable>
                                                            </div>
                                                            <div className="banner_upload">
                                                                <label htmlFor="im_file05">
                                                                    {" "}
                                                                    <img style={{ height: 150,width:150,marginLeft:40 }}  src={details.logo ? details.logo : "./assets/images/banner.png"} alt="" />
                                                                    {/* <img
                                                                        style={{ height: 150 }}
                                                                        className="w-100"
                                                                        src="./assets/images/banner.png"
                                                                        alt=""
                                                                    /> */}
                                                                    <i className="fa-solid fa-camera" />
                                                                </label>
                                                                <input type="file"  onChange={(e) => onChange(e,'LogoImage')} className="d-none" id="im_file05" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-8 ms-auto">
                                                            <div className="form-group">
                                                                <lable htmlFor="lname">Coupon Title</lable>
                                                                <input
                                                                    type="text"
                                                                    name="title"
                                                                    id="lname"
                                                                    value={details?.title}
                                                                    className="form-control"
                                                                    placeholder="Enter"
                                                                    onChange={handleInputChange}
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <lable htmlFor="fname1">Serial Number</lable>
                                                                <input
                                                                    type="text"
                                                                    name="serialNo"
                                                                    id="fname1"
                                                                    className="form-control"
                                                                    value={details?.serialNo}
                                                                    placeholder="Enter"
                                                                    onChange={handleInputChange}
                                                                />
                                                            </div>
                                                        </div>

                                                        {/* <div className="col-md-6">
                                                            <div className="form-group">
                                                                <lable htmlFor="fname1">Serial Number</lable>
                                                                <input
                                                                    type="text"
                                                                    name="serialNo"
                                                                    id="fname1"
                                                                    className="form-control"
                                                                    value={details?.serialNo}
                                                                    placeholder="Enter"
                                                                    onChange={handleInputChange}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <lable htmlFor="lname">Coupon Title</lable>
                                                                <input
                                                                    type="text"
                                                                    name="title"
                                                                    id="lname"
                                                                    value={details?.title}
                                                                    className="form-control"
                                                                    placeholder="Enter"
                                                                    onChange={handleInputChange}
                                                                />
                                                            </div>
                                                        </div> */}
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <lable htmlFor="num">Terms and Condition</lable>
                                                                <textarea
                                                                    name="termsAndConditions"
                                                                    id=""
                                                                    className="form-control"
                                                                    rows={4}
                                                                    placeholder="Type.."
                                                                    defaultValue={details?.termsAndConditions}
                                                                    onChange={handleInputChange}


                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <lable htmlFor="gender">Discount</lable>
                                                                <input
                                                                    type="number"
                                                                    name="discount"
                                                                    value={details?.discount}
                                                                    id=""
                                                                    className="form-control"
                                                                    placeholder="Enter"
                                                                    onChange={handleInputChange}

                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <lable htmlFor="dob">Coupon Code</lable>
                                                                <input
                                                                    type="text"
                                                                    name="code"
                                                                    id="dob"
                                                                    className="form-control"
                                                                    placeholder="Enter"
                                                                    value={details?.code}
                                                                    onChange={handleInputChange}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <lable htmlFor="add">Validity</lable>
                                                                <input
                                                                    type="date"
                                                                    name="add"
                                                                    id="add"
                                                                    value={new Date(details?.validUntil).toJSON()?.slice(0, 10)}
                                                                    className="form-control"
                                                                    placeholder="Enter"
                                                                    onChange={handleInputChange}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <lable htmlFor="add">Coupon Quantity</lable>
                                                                <input
                                                                    type="text"
                                                                    name="maxUses"
                                                                    id="add"
                                                                    className="form-control"
                                                                    placeholder="Enter"
                                                                    value={details?.maxUses}
                                                                    onChange={handleInputChange}

                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <lable htmlFor="num">Description</lable>
                                                                <textarea
                                                                    name="description"
                                                                    id=""
                                                                    className="form-control"
                                                                    rows={4}
                                                                    placeholder="Type.."
                                                                    defaultValue={details?.description}
                                                                    onChange={handleInputChange}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-12 pt-3">

                                                            <Link onClick={DataToSave} className="ed_pbtn btn ms-auto"  >Save</Link>
                                                            {/* <button
                                          type="submit"
                                          className="ed_pbtn btn ms-auto"
                                          data-bs-dismiss="modal"
                                          aria-label="Close"
                                        >
                                          Save
                                        </button> */}
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </>

                    )}




                    {/* \ deshboad */}
                </div>
                {/* \ right Content */}
            </div>
            <Modal  size="lg" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title> Crop Image</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row' >
                        <div className='col-6 ' >
                        <Cropper
                                                            ref={cropperRef}
                                                            style={{ height: 400, width: "100%" }}
                                                            zoomTo={0}
                                                            aspectRatio={(getFile == 'LogoImage') ? 1/1 : 3/1}
                                                            preview=".img-preview"
                                                            src={image}
                                                            className='border border-dark'
                                                            viewMode={1}
                                                            minCropBoxHeight={10}
                                                            minCropBoxWidth={10}
                                                            background={false}
                                                            responsive={true}
                                                            autoCropArea={1}
                                                            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                                            guides={true}
                                                            />
                                                         

                        </div>
                        <div className='col-6  ' >
                        <h5>Preview:</h5>
                                                           {(cropData == '#') ? 'Please Crop Image' :  <img className=' border border-dark' style={{ width: "100%" }} src={cropData} alt="cropped" /> }
                        </div>
                        <div className='col-12 mt-3' >
                            <center>
                            <Button  style={{float:'center'}} variant="primary" onClick={getCropData}>Crop Image</Button>
                            </center>
                        </div>
    
                    </div>

                                                    


                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button disabled={(cropData == '#')} variant="primary" onClick={imguplaod}>
                    Save Changes
                </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
