import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import LogoImage from './../images/MonsterFoodz Logo.png'
import LoginContainerImage from './../images/log_back.png'
const baseUrl = process.env.REACT_APP_API_URL

const Login = () => {
  const username = 'food_app';
  const password = 'food@123';
  const credentials = btoa(`${username}:${password}`);
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const RString = (Math.random() + 1).toString(36).substring(2);
  const headers = {
    "devicetype": "w",
    "deviceid": RString,
    "timezone": timezone,
    "Accept-Language": "en",
    'Authorization': `Basic ${credentials}`
  };
  const navigate = useNavigate()
  const [values, setValues] = useState({
    email: "",
    password: ""
  })
  const handleChange = (e) => {
    const { name, value } = e.target
    setValues({
      ...values,
      [name]: value
    })
  }

  const requestOptions = {
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}api/auth/login`,
    headers: headers,
    data: values
  };


  useEffect(() => {
    const item = JSON.parse(localStorage.getItem("Data"))
    if(item){
      navigate("/dashboard")
    }else{
      navigate("/")
    }
  }, [])

  const handleSubmit = async (e) => {
    try {
      e.preventDefault()
      axios(requestOptions)
        .then(response => {
          localStorage.setItem("Token", response?.data?.data?.accessToken)
          localStorage.setItem("Data", JSON.stringify(response?.data?.data))
          setTimeout(() => {
            window.location.reload()
            navigate("/dashboard")
          }, 200);
        })
        .catch(error => {
          toast.error(error.response.data.message)
        })
    } catch (error) {
      toast.error("internal server error")
    }
  }
  return (
    <>
      <ToastContainer />
      <section className="vh-100 login_page">
        <div className="container py-3 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col col-xl-10">
              <div className="card" style={{ borderRadius: "1rem" }}>
                <div className="row g-0">
                  <div className="col-md-6 col-lg-7 d-none d-md-block">
                    <img
                      src={LoginContainerImage}
                      alt="login form"
                      className="img-fluid h-100 object-fit-cover"
                      style={{ borderRadius: "1rem 0 0 1rem" }}
                    />
                  </div>
                  <div className="col-md-6 col-lg-5 d-flex align-items-center">
                    <div className="card-body p-4 p-lg-5 text-black">
                      {/* <form action="advertising-management.html"></form> */}
                      <form className="loginForm" onSubmit={handleSubmit} >
                        <div className="d-flex justify-content-center">
                          <img src={LogoImage} alt="" width={200} />
                        </div>
                        <h5
                          className="fw-normal mb-3 pb-3"
                          style={{ letterSpacing: 1 }}
                        >
                          Sign into your account
                        </h5>
                        <div className="form-outline mb-3">
                          <label className="form-label" htmlFor="form2Example17">
                            Email address
                          </label>
                          <input
                            id="email"
                            type="email"
                            name="email"
                            tabIndex={1}
                            onChange={handleChange}
                            defaultValue=""
                            className="form-control form-control-lg"
                          />
                        </div>
                        <div className="form-outline mb-3">
                          <label className="form-label" htmlFor="form2Example27">
                            Password
                          </label>
                          <input
                            type="password"
                            name="password"
                            tabIndex={2}
                            onChange={handleChange}
                            id="form2Example27"
                            defaultValue=""
                            className="form-control form-control-lg"
                          />
                        </div>
                        <div className="pt-1 mb-3 w-100">


                          <button type='submit' className="btn btn-dark w-100" style={{
                            backgroundColor: "var(--theme)",
                            borderColor: "var(--theme)"
                          }} >Login </button>
                        </div>
                        {/* <a class="small text-muted text-decoration-none" href="forgot.html">Forgot password?</a>
              <p class="mb-3 pb-lg-2" style="color: #393f81;">Don't have an account? <a
                  class="text-decoration-none" href="register.html" style="color: #393f81;">Register here</a></p> */}
                        <a href="#!" className="small text-muted">
                          Terms of use.
                        </a>
                        <a href="#!" className="small text-muted">
                          Privacy policy
                        </a>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Login