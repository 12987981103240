import React from 'react'
import { Link, useNavigate, useLocation } from "react-router-dom"
import Swal from 'sweetalert2'
import { logout } from '../services/AuthApi'
import LogoImage from './../images/MonsterFoodz Logo.png'


const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const Logout = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to Logout ?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then(async (result) => {
      if (result.isConfirmed) {
        await logout()
        localStorage.clear()
        navigate("/")
      }
    })
  }

  let pathname = location?.pathname
  let newpath = location?.pathname.split("/")
  // console.log(pathname)



  return (
    <>
      <div className="sidebar_wrapper" id="sideOpen">
        <h2 className="side_heading d-flex justify-content-center">
          <img src={LogoImage} className="logo" alt="" />
        </h2>
        <i className="fa-solid fa-xmark close d-xl-none" />
        <h3 className="side_sub_headign">Modern Admin Dashboard</h3>
        <ul className="sidenav_list">
          <li className="sidenav_item">
            <Link to='/dashboard' className={(pathname === "/dashboard" || pathname === `/Advertising_edit/${newpath[2]}` || pathname === `/Advertising_view/${newpath[2]}` || pathname === "/addpromotion") ? "sidenav_link  active" : " sidenav_link"}   > <i className="fa-solid fa-mug-hot" />
              <span>Advertising Management</span> </Link>
          </li>
          <li className="sidenav_item">


            <Link to='/restaurant_manager' className={(pathname === "/restaurant_manager" || pathname === `/Restaurants_view/${newpath[2]}`) ? "sidenav_link  active" : " sidenav_link"}  > <i className="fa-solid fa-bowl-rice" />{" "}
              <span>Restaurant manager</span></Link>
          </li>
          <li className="sidenav_item">

            <Link to='/user_management' className={(pathname ==="/user_management" || pathname === `/user_view/${newpath[2]}`) ? "sidenav_link active" : "sidenav_link"}   >     <i className="fa-solid fa-user-group" />{" "}
              <span>User management</span></Link>
          </li>
          <li className="sidenav_item">


            <Link to='/coupons' className={(pathname === "/coupons" ||pathname==="/addCoupon" || pathname === `/viewCoupon/${newpath[2]}` || pathname === `/editCoupon/${newpath[2]}`) ? "sidenav_link  active" : " sidenav_link"}  >  <i className="fa-solid fa-gift" />
              <span>Coupons</span></Link>
          </li>
          <li className="sidenav_item d-none">
            <a href="chat.html" className="sidenav_link">
              <i className="fa-solid fa-message" /> <span>Chat</span>
            </a>
          </li>
          <li className="sidenav_item">
            <Link
              onClick={() => Logout()}
              className="sidenav_link" >  <i className="fa-solid fa-power-off" /> <span>Logout</span> </Link>
          </li>
        </ul>
      </div>


    </>
  )
}

export default Sidebar