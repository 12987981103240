import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios";
import Sidebar from '../../layouts/Sidebar'
import Navbar from '../../layouts/Navbar'
import { Link, useParams } from 'react-router-dom';
import { Loader } from 'rsuite';
import { Add_promotions, GetPromotions, GetRestaurants, getCatData, update_advertising } from '../../services/AuthApi'
import Swal from 'sweetalert2'

export default function Restaurents_view() {
  const params = useParams();
  const [data, setData] = useState()
  const [catData, setCatData] = useState();
  const [details, setDetails] = useState()
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getrRestData()
    getpromdata()
    get_Data()
  }, []);

  const get_Data = async () => {
    try {
      setLoading(true)
      let data = await getCatData()
      setCatData(data.data.data.data)
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  const Dublicket = async (data) => {
    const datt = await Add_promotions(data)
    toast.success(datt.data.message)
    setTimeout(() => {
      getpromdata()
    }, 1000)
  }


  const hotDeal = async (dataval, id, event) => {
    try {
      const { name, value } = event.target;
      dataval == true ? dataval = false : dataval = true;
      let datad = {
        [name]: dataval
      }
      let dat = await update_advertising(datad, id);
      toast.success(dat.message);
      getpromdata()
    } catch (error) {
      console.log(error)
    }
  }



  const getpromdata = async () => {
    try {
      const permdata = await GetPromotions(params.id)
      if (permdata && permdata.statusCode == 200) {
        setData(permdata.data.data)
        setLoading(false);
      } else {
        toast.error(permdata.data.message)
      }
    } catch (error) {
      console.log(error, '====errrrrrrrrrrrrrrrrrrrrrrr')
    }
  }

  const getrRestData = async () => {
    try {
      const ndata = await GetRestaurants(params.id)
      console.log('ndata',ndata)
      setDetails(ndata)
      setLoading(false)
    } catch (error) {
      toast.error("internal server error")
    }
  }


  const CategoriesName = (id) => {
    let foodname = (catData.find((e) => e?._id == id)?.name)
    return foodname
  }



  const deletePromotion = async (id) => {
    try {
      let token = localStorage.getItem("Token");
      const headers = {
        'Authorization': `Bearer ${token}`
      };


      const requestDelete = {
        method: 'DELETE',
        url: `${process.env.REACT_APP_API_URL}api/admin/promotions/${id}`,
        headers: headers,
      };
      Swal.fire({
        title: 'Are you sure?',
        text: "Want to delete this Food card ?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result && result.isConfirmed) {
          axios(requestDelete)
            .then(response => {
              getpromdata()
              Swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
              )
            })
            .catch(error => {
              toast.error(error.response.data.message)
            })
        }
      })
    } catch (error) {
      toast.error("internal server error")
    }
  }



  return (
    <>
      <ToastContainer />
      <div className="appview">
        {/* sidebar */}
        <Sidebar />
        {/* \ sidebar */}
        {/* right Content */}
        <div className="right_content">
          {/* Navbar */}
          <Navbar />
          {/* \ Navbar */}
          {/* deshboad */}

          {loading ? (
            <Loader backdrop content="Loading..." vertical size="lg" />
          ) : (
            <>
              <section className="wrapper">
                <div className="container-fluid">
                  <div className="wrapper_header d-flex justify-content-between align-items-center">
                    <div>
                      <h2 className="comp_heading">Manager ID {details?._id}</h2>
                      <span className="back_route">
                        {" "}
                        <Link to='/restaurant_manager' >Restaurant manager/ </Link>
                        Restaurant Detaills{" "}
                      </span>
                    </div>
                  </div>
                </div>
              </section>
              <section className="wrapper">
                <div className="container-fluid">
                  <div className="row g-4">
                    <div className="col-lg-3">
                      <div className="user_info">
                        <h3 className="rdh px-3 pb-3">Manager Detail</h3>
                        <div className="user_pro">
                          <img src={details?.merchantId?.profilePicture} alt="" />
                          <a>{details?.merchantId?.userType}</a>
                        </div>
                        <div className="user_det mt-3">
                          <i className="fa-regular fa-user" />
                          <div className="u_text">
                            <strong>Full Name</strong>
                            <small>{details?.merchantId?.fullName}</small>
                          </div>
                        </div>
                        <div className="user_det">
                          <i
                            className="fa-regular fa-message"
                            style={{ color: "#413dfb" }}
                          />
                          <div className="u_text">
                            <strong>Email</strong>
                            <small>{details?.merchantId?.email}</small>
                          </div>
                        </div>
                        <div className="user_det">
                          <i className="fa-solid fa-phone" style={{ color: "#369BFF" }} />
                          <div className="u_text">
                            <strong>Phone Number</strong>
                            <small>{details?.merchantId?.phoneNo}</small>
                          </div>
                        </div>
                        <div className="user_det">
                          <i className="fa-solid fa-hotel" style={{ color: "#5bff36" }} />
                          <div className="u_text">
                            <strong>Restaurant Name</strong>
                            <small>{details?.restaurantName}</small>
                          </div>
                        </div>
                        <div className="user_det">
                          <i
                            className="fa-solid fa-venus-mars"
                            style={{ color: "#ff36cd" }}
                          />
                          <div className="u_text">
                            <strong>Gender </strong>
                            <small>Male</small>
                          </div>
                        </div>
                        <div className="user_det">
                          <i
                            className="fa-solid fa-calendar-days"
                            style={{ color: "#ff3636" }}
                          />
                          <div className="u_text">
                            <strong>Date Of Birth </strong>
                            <small>05/ 05/ 2000</small>
                          </div>
                        </div>
                        <div className="user_det">
                          <i
                            className="fa-solid fa-address-book"
                            style={{ color: "#c300ff" }}
                          />
                          <div className="u_text">
                            <strong>Address</strong>
                            <small>{details?.merchantId?.location?.fullAddress}</small>
                          </div>
                        </div>
                        <div className="user_cfooter">
                          <div className="user_det">
                            <i
                              className="fa-solid fa-truck-fast"
                              style={{ color: "#2D9CDB" }}
                            />
                            <div className="u_text">
                              <small className="text-white">Membership package</small>
                              <strong className="text-white">{details?.merchantId?.plan}</strong>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-9">
                      <div className="rest_detail">
                        <div className="row justify-content-between g-4">
                          <div className="col-lg-12">
                            <h3 className="rdh mb-3">Restaurant Detail</h3>
                            <form>
                              <div className="row g-3">

                                {details ? details.images?.map((e) =>
                                  <div className="col-md-4" key={e}>
                                    <div className="banner_upload">
                                      <label htmlFor="">
                                        {" "}
                                        <img
                                          className="rounded"
                                          src={e}
                                          alt=""
                                        />
                                      </label>
                                    </div>
                                  </div>
                                ) : <div className="col-md-4">
                                  <div className="banner_upload">
                                    <label htmlFor="">
                                      {" "}
                                      <img
                                        className="rounded"
                                        src="/assets/images/banner.png"
                                        alt=""
                                      />
                                    </label>
                                  </div>
                                </div>}

                                <div className="col-12">
                                  <div className="date_text d-flex justify-content-between flex-wrap mb-3">
                                    <div>
                                      <strong className="fw-medium">Open Time :</strong>{" "}
                                      <span>{details?.openingTime}</span>
                                    </div>
                                    <div>
                                      <strong className="fw-medium">Close Time :</strong>{" "}
                                      <span>{details?.closingTime}</span>
                                    </div>
                                  </div>
                                  <h4>{details?.restaurantName}</h4>
                                  <h5 className="mt-4">About :-</h5>
                                  <p>{details?.description}</p>

                                  <div className="d-flex v_del align-items-center">
                                    <span href="" className="nt_box d-md-flex d-none">
                                      <i className="fa-solid fa-utensils" />
                                    </span>
                                    <h5>
                                      Food Categories :
                                      <i className="fw-normal"> {
                                        (details?.foodTypes.map((ftype,index) => {

                                          switch (details.foodTypes.length) {
                                            case 1:
                                              return ftype.name;
                                            break;
                                            case 2:
                                              return  `${ftype.name}${(index == 0) ? 'And ' : ''}`
                                            break;
                                            default:
                                              return  `${ftype.name}${((index +1) == details.foodTypes.length) ? '' : ', '}`
                                            break;
                                          }

                                        }))
                                      }</i>
                                    </h5>
                                  </div>
                                  <div className="d-flex v_del align-items-center mt-3">
                                    <span href="" className="nt_box d-md-flex d-none">
                                      <i className="fa-solid fa-arrow-up-right-dots" />
                                    </span>
                                    <h5>
                                      Plan Type :{" "}
                                      <i className="fw-normal"> {details?.merchantId?.plan} </i>
                                    </h5>
                                  </div>
                                  <div className="d-flex v_del align-items-center mt-3">
                                    <span href="" className="nt_box d-md-flex d-none">
                                      <i className="fa-solid fa-phone"></i>
                                    </span>
                                    <h5>Phone Number : <i className="fw-normal"> {details?.merchantId?.phoneNo} </i>
                                    </h5>
                                  </div>
                                  <div className="d-flex v_del align-items-center mt-3">
                                    <span href="" className="nt_box d-md-flex d-none">
                                      <i className="fa-solid fa-envelope"></i>
                                    </span>
                                    <h5>Email Address : <i className="fw-normal">{details?.merchantId?.email}</i>
                                    </h5>
                                  </div>
                                  <div className="d-flex v_del align-items-center mt-3">
                                    <span href="" className="nt_box d-md-flex d-none">
                                      <i className="fa-solid fa-address-book"></i>
                                    </span>
                                    <h5>Business Address : <i className="fw-normal">{details?.merchantId?.location?.fullAddress}</i></h5>
                                  </div>
                                  <h5 className="mt-4">Terms and Conditions :-</h5>
                                  <p>{details?.termcondition}</p>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="">
                        <div className="wrapper_header d-flex justify-content-between align-items-center mb-5">
                          <div>
                            <h2 className="comp_heading">Advertisements</h2>
                          </div>
                          <Link className="ico_box active " to={`/addpromotion?id=${params.id}`}>   <i className="fa-solid fa-plus" />
                          </Link>
                        </div>
                        <div
                          className="gird_gallery"
                          id="gList"
                          style={{ justifyContent: "start" }}
                        >
                          {data ?
                            data?.map((list) =>
                              //  data
                              <div className="card" key={list?._id}>
                                <div className="g_card">
                                  <div className="card_top">
                                    <img src={list?.images[0]} alt="" />
                                  </div>
                                  <div className="card_body">
                                    <h3 className="card_name mb-0 fw-medium">
                                      {/* (O, HP, BC) */}
                                      {(list.isOfferOfTheDay == false && list.isHotPromotion ==false && list.isBestChoice == false  ) ?  <span className='text-white' > .</span> : ""} 
                                      {(list.isOfferOfTheDay == true && list.isHotPromotion == false && list.isBestChoice == false) ? "(O)" : ""} {(list.isOfferOfTheDay == false && list.isHotPromotion == true && list.isBestChoice == false) ? "(HP)" : ""}  {(list.isOfferOfTheDay == false && list.isHotPromotion == false && list.isBestChoice == true) ? "(BC)" : ""} {(list.isOfferOfTheDay == true && list.isHotPromotion == true && list.isBestChoice == false) ? "(O,HP)" : ""} {(list.isOfferOfTheDay == true && list.isHotPromotion == false && list.isBestChoice == true) ? "(O,BC)" : ""} {(list.isOfferOfTheDay == false && list.isHotPromotion == true && list.isBestChoice == true) ? "(HP,BC)" : ""} {(list.isOfferOfTheDay == true && list.isHotPromotion == true && list.isBestChoice == true) ? "(O,HP,BC)" : ""}

                                    </h3>
                                    <h3 className="card_name">{list?.name}</h3>
                                    <p className="food_name">
                                      {list?.food_category[0] ? list?.food_category[0] : "N/A"}  </p>
                                    <div className="card_icos">
                                      <Link className="text-decoration-none" to={`/Advertising_view/${list?._id}`}   > <div className="ico_s">
                                        <i className="fa-regular fa-eye" />
                                      </div>
                                        <span>View</span> </Link>

                                      <Link to={`/Advertising_edit/${list?._id}`} className="text-decoration-none" >
                                        <div className="ico_s red">
                                          <i className="fa-regular fa-pen-to-square" />
                                        </div>
                                        <span>Edit</span></Link>


                                      <a
                                        className="text-decoration-none"

                                        onClick={() => deletePromotion(list?._id)}
                                      >
                                        <div className="ico_s blue">
                                          <i className="fa-regular fa-trash-can" />
                                        </div>
                                        <span>Delete</span>
                                      </a>
                                      <a
                                        className="text-decoration-none"
                                        onClick={() => Dublicket(list)}
                                      >
                                        <div className="ico_s purple">
                                          {" "}
                                          <i className="fa-regular fa-square-plus" />{" "}
                                        </div>
                                        <span>Duplicate</span>
                                      </a>
                                    </div>
                                  </div>
                                  <div className="dropdown gc_drop"   >
                                    <a className="" href="#" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                                      <i className="fa-solid fa-ellipsis"></i>
                                    </a>

                                    <ul className="dropdown-menu dropdown-menu-end">
                                      <li className="check_group">
                                        <input type="checkbox" id={`${list?._id}1`} name='isOfferOfTheDay' onChange={(e) => hotDeal(list?.isOfferOfTheDay, list?._id, e)} checked={list?.isOfferOfTheDay ? list?.isOfferOfTheDay : ""} />
                                        <label htmlFor={`${list?._id}1`}>Offer of the day <i className="fa-solid fa-check"></i></label>
                                      </li>
                                      <li className="check_group">
                                        <input type="checkbox" id={`${list?._id}2`} name='isHotPromotion' onChange={(e) => hotDeal(list?.isHotPromotion, list?._id, e)} checked={list?.isHotPromotion ? list?.isHotPromotion : ""} />
                                        <label htmlFor={`${list?._id}2`}>Hot promotions <i className="fa-solid fa-check"></i></label>
                                      </li>
                                      <li className="check_group">
                                        <input type="checkbox" id={`${list?._id}3`} name='isBestChoice' onChange={(e) => hotDeal(list?.isBestChoice, list?._id, e)} checked={list?.isBestChoice ? list?.isBestChoice : ""} />
                                        <label htmlFor={`${list?._id}3`}>Best choice  <i className="fa-solid fa-check"></i></label>
                                      </li>
                                      <li></li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            )
                            : <span>No Data found</span>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/* \ deshboad */}
            </>
          )}

        </div>
        {/* \ right Content */}
      </div>


    </>
  )
}
