import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios";
import Sidebar from '../../layouts/Sidebar'
import Navbar from '../../layouts/Navbar'
import { Loader } from 'rsuite';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'

export default function Coupon() {
    let [data, setData] = useState()
    const [loading, setLoading] = useState(true);
    let token = localStorage.getItem("Token");
    const headers = {
        'Authorization': `Bearer ${token}`
    };

    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}api/coupon/listing?pageNo=1&limit=10&status=ACTIVE`,
        headers: headers,
    };
    useEffect(() => {
        getCouponData()
    }, []);

    const getCouponData = async () => {
        try {
            axios(requestOptions)
                .then(response => {
                    setData(response.data.data.data)
                    setLoading(false);
                    console.log('Data',response.data.data.data)
                })
                .catch(error => {
                    toast.error(error.response.data.message)
                })
        } catch (error) {
            toast.error("internal server error")
        }
    }

    const deleteCoupon = (id) => {
        try {
            const requestDelete = {
              method: 'DELETE',
              url: `${process.env.REACT_APP_API_URL}api/coupon/${id}`,
              headers: headers,
            };
            Swal.fire({
              title: 'Are you sure?',
              text: "Are you sure want to Delete this Coupon?",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
            //   console.log(result,'==============')
              if (result.isConfirmed) {
                axios(requestDelete)
                  .then(response => {
                    getCouponData()
                    Swal.fire(
                      'Deleted!',
                      'Your file has been deleted.',
                      'success'
                    )
                  })
                  .catch(error => {
                    toast.error(error.response.data.message)
                  })
              }
            })
          } catch (error) {
            toast.error("internal server error")
          } 
    }


    return (
        <>
            <ToastContainer />
            <div className="appview">
                <Sidebar />
                <div className="right_content">
                    <Navbar />
                    {loading ? (
                        <Loader backdrop content="Loading..." vertical size="lg" />
                    ) : (
                        <>
                            <section className="wrapper">
                                <div className="container-fluid">
                                    <div className="wrapper_header d-flex justify-content-between align-items-center">
                                        <div>
                                            <h2 className="comp_heading">Coupons</h2>
                                            <h4 className="comp_sub_heading">
                                                Hi, Samantha. Welcome back to Sedap Admin!s
                                            </h4>
                                        </div>

                                        <Link className="ico_box active " to="/addCoupon" ><i className="fa-solid fa-plus" />  </Link>

                                    </div>
                                </div>
                            </section>
                            <section className="wrapper">
                                <div className="container-fluid">
                                    <div className="row g-3 bg-white p-ms-5 p-2 rounded">
                                        {data.length == 0 ? <span>No Data Found</span> :
                                            data.map((e) =>
                                                <div className="col-xxl-4 col-lg-6 col-sm-10 col-12 mb-3" key={e?._id}>

                                                    <Link className="coupon_card" to={`/viewCoupon/${e._id}`}>
                                                        <div className="card_detail">
                                                        <div className="btn-group t_dropdown text-light">
                                                                <i
                                                                    className="fa-solid fa-ellipsis"
                                                                    data-bs-toggle="dropdown"
                                                                    aria-expanded="false"
                                                                />
                                                                <ul className="dropdown-menu dropdown-menu-end">
                                                                    <li>
                                                                        <Link className="dropdown-item" to={`/viewCoupon/${e?._id}`}> View Detail</Link>
                                                                    </li>

                                                                    <li>
                                                                        <Link   className="dropdown-item text-danger"
                                                                            onClick={() => deleteCoupon(e?._id)}>Delete </Link>
                                                                       
                                                                    </li>
                                                                </ul>
                                                               
                                                                
                                                            </div>

                                                            <h3>{e?.discount}% {e?.title}     </h3> 
                                                            {/* <button>Get Coupon code</button> */}
                                                            <button>{e?.code}</button>                                                    <p>
                                                                {e?.description}
                                                            </p>
                                                        </div>
                                                        <div  className="coupon_offer">
                                                            <img className='logo-image' src={e?.logo ? e?.logo : "assets/images/coupimg.png"} alt="" />
                                                            {/* <img className='cover-image' src={e?.image ? e?.image : "assets/images/coupimg.png"} alt="" /> */}

                                                            <div className='mt-4 text-center' >

                                                            <strong>{e?.discount}%</strong> <br></br>
                                                            <span>off</span> <br></br>
                                                            <span>Discount</span>
                                                         
                                                            <span> {new Date(e?.validUntil) >= new Date()? <span className='btn btn-outline-success btn-sm'>Active</span>  : <span className='btn btn-outline-danger btn-sm' >Expired</span>  } </span>
                                                            </div>
                                                        </div>
                                                        
                                                    </Link>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </section>
                            {/* \ deshboad */}
                        </>
                    )}




                </div>
                {/* \ right Content */}
            </div>

        </>

    )
}
