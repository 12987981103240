// import './App.css';
import { Route, Routes } from 'react-router-dom';
import Login from './component/Login';
import Dashboard from './component/Advertising/Dashboard';
import Advertising_view from './component/Advertising/Advertising_view';
import Advertising_edit from './component/Advertising/Edit_advertising'; 
import AddPromotation from './component/Advertising/AddPromotation';
import Notifaction from './component/Extra/Notification';

import Coupon from './component/Coupon/Coupon';
import AddCoupon from './component/Coupon/AddCoupon';
import ViewCoupon from './component/Coupon/ViewCoupon';

import UserList from './component/Users/UserList';
import User_view from './component/Users/User_view';
import Edit_user from './component/Users/Edit_user';


import Restaurants from './component/Restaurants/Restaurants';
import Restaurants_view from './component/Restaurants/Restaurents_view';
import Restaurants_edit from './component/Restaurants/Restaurant_edit';
import EditCoupon from './component/Coupon/EditCoupon';





// import PrivateOutlet from './services/AuthToken';
function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        {/* <Route element={<PrivateOutlet/>}> */}
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/Advertising_view/:id' element={<Advertising_view />} />
        <Route path='/Advertising_edit/:id' element={<Advertising_edit />} />
        <Route path='/addpromotion' element={<AddPromotation />} />
        <Route path='/notification' element={<Notifaction />} />



        <Route path='/coupons' element={<Coupon />} />
        <Route path='/addCoupon' element={<AddCoupon />} />
        <Route path='/viewCoupon/:id' element={<ViewCoupon />} />
        <Route path='/editCoupon/:id' element={<EditCoupon />} />
        



        <Route path='/user_management' element={<UserList />} />
        <Route path='/user_view/:id' element={<User_view />} />
        <Route path='/editUser/:id' element={<Edit_user />} />



        <Route path='/restaurant_manager' element={<Restaurants />} />
        <Route path='/Restaurants_view/:id' element={<Restaurants_view />} />
        <Route path='/Restaurants_edit/:id' element={<Restaurants_edit />} />

        {/* </Route> */}
      </Routes>
    </>
  );
}
export default App;
