import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios";
import Sidebar from '../../layouts/Sidebar'
import Navbar from '../../layouts/Navbar'
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'
import { Add_promotions, update_advertising, getCatData, GetDashPromotions } from '../../services/AuthApi'
import { Loader } from 'rsuite';

export default function Dashboard() {
  const [data, setData] = useState()
  const [loading, setLoading] = useState(true);
  const [catData, setCatData] = useState();
  const [hotPromotion, setHotPromotion] = useState(0)
  const [offerOfTheDay, setisOfferOfTheDay] = useState(0)
  const [bestChoice, setBestChoice] = useState(0)

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totaldata, setTotaldata] = useState(0);
  const [limit, setLimit] = useState(12);
  const [nhit, setNhit] = useState(0);

  let token = localStorage.getItem("Token");
  const headers = {
    'Authorization': `Bearer ${token}`
  };
  useEffect(() => {
    getDashboardData(`pageNo=${currentPage}&limit=${limit}`)
    get_Data()
    fildterata()
  }, [currentPage]);


  const getDashboardData = async (data) => {
    try {
      const dataa = await GetDashPromotions(data)
      if (dataa.statusCode == 200) {
        setData(dataa.data.data)
        setCurrentPage(dataa.data.pageNo)
        setTotalPages(dataa.data.totalPage)
        setLimit(dataa.data.limit)
        setTotaldata(dataa.data.total)
        setNhit(dataa.data.nextHit)
        setLoading(false);
      } else {
        console.log(dataa, '====errrrrrrrrrrrrrrrrrrrrr')
      }
    } catch (error) {
      toast.error("internal server error")
    }
  }

  const handleNextPage = () => {
    getDashboardData(`pageNo=${nhit}&limit=${limit}`)
  };

  const searchData = (e) => {
    if (e.target.value) {
      getDashboardData(`searchKey=${e.target.value}&pageNo=${nhit}&limit=${limit}`)
    } else {
      getDashboardData(`pageNo=${nhit}&limit=${limit}`)
    }

  }


  const fildterata = () => {
    setHotPromotion(data?.filter(e => e.isHotPromotion == true).length)
    setisOfferOfTheDay(data?.filter(e => e.isOfferOfTheDay == true).length)
    setBestChoice(data?.filter(e => e.isBestChoice == true).length)
  }

  const get_Data = async () => {
    try {
      setLoading(true)
      let data = await getCatData()
      setCatData(data.data.data.data)
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  const Dublicket = async (data) => {
    const datt = await Add_promotions(data)

    toast.success(datt.data.message)
    setTimeout(() => {
      getDashboardData(`pageNo=${currentPage}&limit=${limit}`)
    }, 1000)
  }

  const deletePromotion = async (id) => {
    try {
      const requestDelete = {
        method: 'DELETE',
        url: `${process.env.process.env.REACT_APP_API_URL}/api/admin/promotions/${id}`,
        headers: headers,
      };
      Swal.fire({
        title: 'Are you sure?',
        text: "Want to delete this Food card ?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result && result.isConfirmed) {
          axios(requestDelete)
            .then(response => {
              getDashboardData(`pageNo=${currentPage}&limit=${limit}`)
              Swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
              )
            })
            .catch(error => {
              toast.error(error.response.data.message)
            })
        }
      })
    } catch (error) {
      toast.error("internal server error")
    }
  }

  const OfferOfTheDaydata = async (dataval, id, event) => {
    try {
      const { name } = event.target;
      dataval == true ? dataval = false : dataval = true;
      let datad = {
        [name]: dataval
      }
      console.log(offerOfTheDay, '=====offerOfTheDay')
      if (offerOfTheDay >= 5) {
      console.log('offerOfTheDay','Check Works')

        if (dataval == false) {
          let dat = await update_advertising(datad, id);

          console.log('dat',dat);
          toast.success(dat.message);
          getDashboardData(`pageNo=${currentPage}&limit=${limit}`)
          dataval ? setisOfferOfTheDay(prevCount => prevCount + 1) : setisOfferOfTheDay(prevCount => prevCount - 1)
        } else {
          toast.error("Remove 'Offer Of The Day' from previous ads (Limit Exceeded')");
        }
      } else {

        let dat = await update_advertising(datad, id);
        // console.log('dat',dat);
        
        toast.success(dat.message);
        getDashboardData(`pageNo=${currentPage}&limit=${limit}`)
        dataval ? setisOfferOfTheDay(prevCount => prevCount + 1) : setisOfferOfTheDay(prevCount => prevCount - 1)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const HotPromotionData = async (dataval, id, event) => {
    try {
      const { name } = event.target;
      dataval == true ? dataval = false : dataval = true;
      let datad = {
        [name]: dataval
      }

      if (hotPromotion >= 5) {
        if (dataval == false) {
          let dat = await update_advertising(datad, id);
          toast.success(dat.message);
          getDashboardData(`pageNo=${currentPage}&limit=${limit}`)
          dataval ? setHotPromotion(prevCount => prevCount + 1) : setHotPromotion(prevCount => prevCount - 1)
        } else {
          toast.error("Please Unselct Hot Promotion");
        }
      } else {

        let dat = await update_advertising(datad, id);
        toast.success(dat.message);
        getDashboardData(`pageNo=${currentPage}&limit=${limit}`)
        dataval ? setHotPromotion(prevCount => prevCount + 1) : setHotPromotion(prevCount => prevCount - 1)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const BestChoiceData = async (dataval, id, event) => {
    try {
      const { name } = event.target;
      dataval == true ? dataval = false : dataval = true;
      let datad = {
        [name]: dataval
      }

      // console.log(bestChoice,'=====bestChoice')
      if (bestChoice >= 5) {
        if (dataval == false) {
          let dat = await update_advertising(datad, id);
          toast.success(dat.message);
          getDashboardData(`pageNo=${currentPage}&limit=${limit}`)
          dataval ? setBestChoice(prevCount => prevCount + 1) : setBestChoice(prevCount => prevCount - 1)
        } else {
          toast.error("Please Unselct Best Choice");
        }
      } else {

        let dat = await update_advertising(datad, id);
        toast.success(dat.message);
        getDashboardData(`pageNo=${currentPage}&limit=${limit}`)
        dataval ? setBestChoice(prevCount => prevCount + 1) : setBestChoice(prevCount => prevCount - 1)
      }
    } catch (error) {
      console.log(error)
    }
  }

  // const hotDeal = async (dataval, id, event) => {
  //   try {
  //     const { name } = event.target;
  //     dataval == true ? dataval = false  : dataval = true;
  //     let datad = {
  //       [name]: dataval
  //     }

  //     let msg = ''
  //     let coundata = 0
  //     switch (name) {
  //       case "isOfferOfTheDay":
  //         coundata = offerOfTheDay
  //         msg = "Offer Of The Day"
  //         break;
  //       case "isHotPromotion":
  //         coundata = hotPromotion
  //         msg = "Hot Promotion"
  //         break;
  //       case "isBestChoice":
  //         coundata = bestChoice
  //         msg = "Best Choice"
  //         break;
  //       default:
  //     }
  //   console.log(dataval==false)
  //   console.log(coundata,'====sssssss')
  //     if(coundata>=5){
  //       if(dataval==false){
  //         let dat = await update_advertising(datad, id);
  //       toast.success(dat.message);
  //       getDashboardData()
  //       if(name==="isHotPromotion"){
  //         dataval ? setHotPromotion(prevCount => prevCount + 1) : setHotPromotion(prevCount => prevCount - 1)
  //       }
  //       if(name==="isOfferOfTheDay"){
  //         dataval ? setisOfferOfTheDay(prevCount => prevCount + 1) : setisOfferOfTheDay(prevCount => prevCount - 1)
  //       }
  //       if(name==="isBestChoice"){
  //         dataval ? setBestChoice(prevCount => prevCount + 1) : setBestChoice(prevCount => prevCount - 1)
  //       }
  //       }else{
  //         toast.error("Please Unselct "+msg); 
  //       }
  //     }else{

  //       let dat = await update_advertising(datad, id);
  //       toast.success(dat.message);
  //       getDashboardData()
  //       if(name==="isHotPromotion"){
  //         dataval ? setHotPromotion(prevCount => prevCount + 1) : setHotPromotion(prevCount => prevCount - 1)
  //       }
  //       if(name==="isOfferOfTheDay"){
  //         dataval ? setisOfferOfTheDay(prevCount => prevCount + 1) : setisOfferOfTheDay(prevCount => prevCount - 1)
  //       }
  //       if(name==="isBestChoice"){
  //         dataval ? setBestChoice(prevCount => prevCount + 1) : setBestChoice(prevCount => prevCount - 1)
  //       }

  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }



  // console.log(hotPromotion,"hotPromotion,'===")
  // console.log(offerOfTheDay,"===offerOfTheDay")
  //  console.log(bestChoice,'===bestChoice')

  const CategoriesName = (id) => {
    let foodname = (catData.find((e) => e?.name == id)?.name)
    return foodname
  }

  return (

    <>
      <ToastContainer />
      <div className="appview">
        {/* sidebar */}
        <Sidebar />
        {/* \ sidebar */}
        {/* right Content */}
        <div className="right_content">
          {/* Navbar */}
          <Navbar />
          {/* \ Navbar */}
          {/* deshboad */}
          {loading ? (
            <Loader backdrop content="Loading..." vertical size="lg" />
          ) : (
            <>
              <section className="wrapper pb-sm-5 pb-2">
                <div className="container-fluid">
                  <div className="wrapper_header row align-items-center">
                    <div className="col-xxl-5 col-lg-4">
                      <h2 className="comp_heading">Advertising Management</h2>
                      <h4 className="comp_sub_heading">
                        Here's your ad management summary with chart view
                      </h4>
                    </div>
                    <div className="col-xxl-7 col-lg-8 d-flex justify-content-end gap-3 align-items-center flex-md-nowrap flex-wrap">
                      <div className="mini_search">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search here"
                            onChange={(e) => searchData(e)}
                          />
                          <i className="fa-solid fa-magnifying-glass sera_ico" />
                        </div>
                      </div>
                      {/* <i
                        className="fa-solid fa-list ico_box d-sm-flex d-none"
                      // onclick="gList()"
                      />
                      <i
                        className="fa-solid fa-table-cells-large ico_box active  d-sm-flex d-none"
                      // onclick="gGrid()"
                      /> */}

                      {/* <Link className="ico_box active " to='/addpromotion'>   <i className="fa-solid fa-plus" />
                      </Link> */}

                      {/* <foodadd></foodadd> */}
                    </div>
                  </div>
                </div>

              </section>
              <section className="wrpper">
                <div className="container-fluid">
                  {/* {/* <div className="gird_gallery grid_list"> * /} */}
                  <div className="gird_gallery" id="gList">

                    {data ?
                      data?.map((list, i) =>


                        <div className="card" key={i}>
                          <div className="g_card">
                            <div className="card_top">
                              <img src={list?.images[0]} alt="" />
                            </div>
                            <div className="card_body">
                              <h3 className="card_name mb-0 fw-medium">

                                {/* (O, HP, BC) */}


                                {(list.isOfferOfTheDay == true && list.isHotPromotion ==false && list.isBestChoice == false  ) ? "(O)" : ""}
                                
                                {(list.isOfferOfTheDay == false && list.isHotPromotion ==false && list.isBestChoice == false  ) ?  <span className='text-white' > .</span> : ""} 
                                {(list.isOfferOfTheDay == false && list.isHotPromotion == true && list.isBestChoice == false  ) ? "(HP)" : ""} 
                                
                                 {(list.isOfferOfTheDay == false && list.isHotPromotion == false && list.isBestChoice == true  ) ? "(BC)" : ""} 
                                {(list.isOfferOfTheDay == true && list.isHotPromotion == true && list.isBestChoice == false  ) ? "(O,HP)" : ""} 
                                
                                {(list.isOfferOfTheDay == true && list.isHotPromotion == false && list.isBestChoice == true  ) ? "(O,BC)" : ""}
                                
                                 {(list.isOfferOfTheDay == false && list.isHotPromotion == true && list.isBestChoice == true  ) ? "(HP,BC)" : ""} 
                                 
                                 {(list.isOfferOfTheDay == true && list.isHotPromotion == true && list.isBestChoice == true  ) ? "(O,HP,BC)" : ""}
                              </h3>
                              <h3 className="card_name">{list?.name}</h3>
                              <p className="food_name">

                                {list?.food_category[0]}
                                {/* {CategoriesName(list?.food_category[0]) ? CategoriesName(list?.food_category[0]) : "N/A"}  
                                */}
                                </p>
                              <div className="card_icos">
                                <Link className="text-decoration-none" to={`/Advertising_view/${list._id}`}   > <div className="ico_s">
                                  <i className="fa-regular fa-eye" />
                                </div>
                                  <span>View</span> </Link>

                                <Link to={`/Advertising_edit/${list._id}`} className="text-decoration-none" >
                                  <div className="ico_s red">
                                    <i className="fa-regular fa-pen-to-square" />
                                  </div>
                                  <span>Edit</span></Link>


                                <a
                                  className="text-decoration-none"

                                  onClick={() => deletePromotion(list._id)}
                                >
                                  <div className="ico_s blue">
                                    <i className="fa-regular fa-trash-can" />
                                  </div>
                                  <span>Delete</span>
                                </a>
                                {/* <a
                                  className="text-decoration-none"
                                  onClick={() => Dublicket(list)}
                                // data-bs-toggle="modal"
                                // data-bs-target="#food_duplicate"
                                >
                                  <div className="ico_s purple">
                                    {" "}
                                    <i className="fa-regular fa-square-plus" />{" "}
                                  </div>
                                  <span>Duplicate</span>
                                </a> */}
                              </div>
                            </div>
                            <div className="dropdown gc_drop"   >
                              <a className="" href="#" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                                <i className="fa-solid fa-ellipsis"></i>
                              </a>

                              <ul className="dropdown-menu dropdown-menu-end">
                                <li className="check_group">
                                  <input type="checkbox" id={`${list?._id}1`} name='isOfferOfTheDay' onChange={(e) => OfferOfTheDaydata(list.isOfferOfTheDay, list._id, e)} checked={list.isOfferOfTheDay ? list.isOfferOfTheDay : ""} />
                                  <label htmlFor={`${list?._id}1`}>Offer of the day <i className="fa-solid fa-check"></i></label>
                                </li>
                                <li className="check_group">
                                  <input type="checkbox" id={`${list?._id}2`} name='isHotPromotion' onChange={(e) => HotPromotionData(list.isHotPromotion, list._id, e)} checked={list.isHotPromotion ? list.isHotPromotion : ""} />
                                  <label htmlFor={`${list?._id}2`}>Hot promotions <i className="fa-solid fa-check"></i></label>
                                </li>
                                <li className="check_group">
                                  <input type="checkbox" id={`${list?._id}3`} name='isBestChoice' onChange={(e) => BestChoiceData(list.isBestChoice, list._id, e)} checked={list.isBestChoice ? list.isBestChoice : ""} />
                                  <label htmlFor={`${list?._id}3`}>Best choice  <i className="fa-solid fa-check"></i></label>
                                </li>
                                <li></li>
                              </ul>
                            </div>
                          </div>
                        </div>




                      )
                      : <span>No Data found</span>}
                  </div>
                  <div className="table_footer">
                    <div className="row align-items-center g-3">
                      <div className="col-md-6 text-md-start text-center">
                        <p>Showing {currentPage} from {totaldata} data</p>
                      </div>
                      <div className="col-md-6">
                        <nav aria-label="Page navigation example">
                          <ul className="pagination ms-auto me-md-0 me-auto">
                            <li className="page-item">
                              <Link className="page-link" onClick={() => handleNextPage(0)} >
                                <span aria-hidden="true">
                                  <i className="fa-solid fa-angles-left" />
                                </span>
                              </Link>
                              {/* <a className="page-link" href="#" aria-label="Previous">
                            <span aria-hidden="true">
                                <i className="fa-solid fa-angles-left" />
                              </span>
                            </a> */}
                            </li>
                            {[...Array(totalPages)].map((e, i) =>
                              <li onClick={()=>setCurrentPage(i+1) }  className="page-item" key={i}>
                                <Link className={currentPage == i + 1 ? "page-link active" : "page-link"}>
                                  {i + 1}
                                </Link>
                                {/* <a className="page-link active" href="#">
                            {i+1}
                          </a> */}
                              </li>
                            )}
                            {/* <li className="page-item">
                            <a className="page-link" href="#">
                              2
                            </a>
                          </li>
                          <li className="page-item">
                            <a className="page-link" href="#">
                              3
                            </a>
                          </li> */}
                            <li className="page-item">
                              <Link className="page-link" onClick={() => handleNextPage()} >
                                <span aria-hidden="true">
                                  <i className="fa-solid fa-angles-right" />
                                </span>
                              </Link>
                              {/* <a className="page-link" href="#" aria-label="Next">
                              <span aria-hidden="true">
                                <i className="fa-solid fa-angles-right" />
                              </span>
                            </a> */}
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </>
          )}

          {/* \ deshboad */}
        </div>
        {/* \ right Content */}
      </div>
      {/* food_duplicate */}
      <div
        className="modal fade"
        id="food_duplicate"
        tabIndex={-1}
        aria-labelledby="food_duplicateLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-sm modal-dialog-centered">
          <div className="modal-content py-3">
            <div className="justify-content-center modal-header p-0 border-0">
              <h4 className="">Duplicate Food Item</h4>
              <button
                type="button"
                className="btn_close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="fa-solid fa-xmark" />
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="row">
                  <div className="col-12">
                    <p className="px-3 text-center">
                      Are you sure want to duplicate this Food Item ?
                    </p>
                  </div>
                  <div className="col-12 pt-3 d-flex align-items-center justify-content-center">
                    <button
                      type="button"
                      className="ed_pbtn btn mx-1 del_btn"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      No
                    </button>
                    <button
                      type="button"
                      className="ed_pbtn btn mx-1"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* \ food_duplicate */}
      {/* food_delete */}
      <div
        className="modal fade"
        id="food_delete"
        tabIndex={-1}
        aria-labelledby="food_deleteLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-sm modal-dialog-centered">
          <div className="modal-content py-3">
            <div className="justify-content-center modal-header p-0 border-0">
              <h4 className="">Delete Food Detail</h4>
              <button
                type="button"
                className="btn_close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="fa-solid fa-xmark" />
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="row">
                  <div className="col-12">
                    <p className="px-3 text-center">
                      Are you sure want to delete this Food card ?
                    </p>
                  </div>
                  <div className="col-12 pt-3 d-flex align-items-center justify-content-center">
                    <button
                      type="button"
                      className="ed_pbtn btn mx-1"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      No
                    </button>
                    <button
                      type="button"
                      className="ed_pbtn btn mx-1 del_btn"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>



  )
}
