import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios";
import Sidebar from '../../layouts/Sidebar'
import Navbar from '../../layouts/Navbar'
import { useParams,Link } from 'react-router-dom';
import { Loader } from 'rsuite';
import { GetRestaurants } from '../../services/AuthApi'

export default function Advertising_view() {
  const params = useParams();
  const [details, setDetails] = useState()
  const [loading, setLoading] = useState(true);
  const [restID,setrestID] = useState(null);
  const [restName,setrestName] = useState('');
  const [restPlan,setrestPlan] = useState('');
  let token = localStorage.getItem("Token");
  const headers = {
    'Authorization': `Bearer ${token}`
  };

  const requestOptions = {
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}api/admin/promotions/${params.id}`,
    headers: headers,
  };
  useEffect(() => {
    getDashboardData()
  }, []);

  useEffect(() => {

    if(restID){
      getRestDetails()
    }

  },[restID])

  const getDashboardData = async () => {
    try {
      axios(requestOptions)
        .then(response => {
          console.log(response.data.data)
          setDetails(response.data.data)
          setLoading(false);
          if(response.data.data.restaurantId){
            setrestID(response.data.data.restaurantId)
          }

        })
        .catch(error => {
          toast.error(error.response.data.message)
        })
    } catch (error) {
      toast.error("internal server error")
    }
  }

  const getRestDetails = async () => {
      const GetRestaurantData = await GetRestaurants(restID);
      console.log('GetRestaurantData',GetRestaurantData)
      setrestName(GetRestaurantData.restaurantName);
      setrestPlan(GetRestaurantData?.merchantId?.plan);
      

  }



  return (
    <div className="appview">
      <ToastContainer />
      {/* sidebar */}
      <Sidebar />
      {/* \ sidebar */}
      {/* right Content */}
      <div className="right_content">
        {/* Navbar */}
        <Navbar />
        {/* \ Navbar */}
        {/* deshboad */}
        {loading ? (
        <Loader backdrop content="Loading..." vertical size="lg" />
      ) : (
       <>
        <section className="wrapper">
          <div className="container-fluid">
            <div className="wrapper_header d-flex justify-content-between align-items-center">
              <div>
                <h2 className="comp_heading">Details</h2>
                <span className="back_route">
                <Link to='/dashboard' >Advertising Management /</Link>
               
                  Promotion Details
                </span>
              </div>
            </div>
          </div>
        </section>
        <section className="wrapper">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="profile_page">
                  <form>
                    <div className="row g-3">
                      {details?.images.map((e) =>
                        <div className="col-md-4" key={e}>
                          <div className="banner_upload">
                            <label htmlFor="">
                              {" "}
                              <img src={e ? e : "/assets/images/banner.png"} alt="" />
                            </label>
                          </div>
                        </div>

                      )}




                      {/* <div className="col-md-4">
                      <div className="banner_upload">
                        <label htmlFor="">
                          {" "}
                          <img src="/assets/images/banner.png" alt="" />
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="banner_upload">
                        <label htmlFor="">
                          {" "}
                          <img src="/assets/images/banner.png" alt="" />
                        </label>
                      </div>
                    </div> */}
                      <div className="col-12">
                        <div className="date_text d-flex justify-content-between flex-wrap mb-3">
                          <div>
                            <strong className="fw-medium">Start Date :</strong>{" "}
                            <span>{new Date(details?.startDate).toJSON()?.slice(0, 10)}</span>
                          </div>
                          <div>
                            <strong className="fw-medium">End Date :</strong>{" "}
                            <span>{new Date(details?.endDate).toJSON()?.slice(0, 10)}</span>
                          </div>
                        </div>
                        <h4>{details?.name}</h4>
                        <hr></hr>
                        <h5> Restaurant Name :- </h5>
                        <p> {(restName) ? restName : 'N/A'}</p>
                        <h5 className="mt-4">Promotion Details :-</h5>
                        <p>{details?.description}</p>
                        {/* <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing elit.
                          Fugiat consequatur fugit perferendis ratione amet nobis
                          eum velit excepturi nostrum recusandae, saepe earum,
                          doloribus itaque illum, incidunt ullam quia in deleniti
                          possimus voluptatibus aspernatur. Obcaecati accusantium
                          officia porro suscipit, omnis repellendus saepe optio
                          voluptatem sequi explicabo fugit necessitatibus
                          consequatur quibusdam nesciunt.
                        </p> */}
                        <div className="d-flex v_del align-items-center">
                          <span href="" className="nt_box d-md-flex d-none">
                            <i className="fa-solid fa-utensils" />
                          </span>
                          <h5>
                            Food Categories :
                            {details?.food_category ? details?.food_category.map((e) =>
                              <i className="fw-normal" key={e}> {e ? e : ""} </i>
                            ) : ""}


                          </h5>
                        </div>
                        <div className="d-flex v_del align-items-center mt-3">
                          <span href="" className="nt_box d-md-flex d-none">
                            <i className="fa-solid fa-arrow-up-right-dots" />
                          </span>
                          <h5>
                            Promotion Type :{" "}
                            <i className="fw-normal"> {details?.discount?.value}% </i>
                          </h5>
                        </div>
                        <h5 className="mt-4">Terms and Conditions :-</h5>
                        <p>{details?.termsAndConditions}</p>
                        {/* <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing elit.
                          Fugiat consequatur fugit perferendis ratione amet nobis
                          eum velit excepturi nostrum recusandae, saepe earum,
                          doloribus itaque illum, incidunt ullam quia in deleniti
                          possimus voluptatibus aspernatur. Obcaecati accusantium
                          officia porro suscipit, omnis repellendus saepe optio
                          voluptatem sequi explicabo fugit necessitatibus
                          consequatur quibusdam nesciunt.
                        </p> */}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
       </>
      )}


       
        {/* \ deshboad */}
      </div>
      {/* \ right Content */}
    </div>

  )
}

