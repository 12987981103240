import React, { useEffect, useState } from 'react'
import Sidebar from '../../layouts/Sidebar'
import Navbar from '../../layouts/Navbar'
import { edit_restaurants, fileUpload, update_Restaurent } from '../../services/AuthApi'
import { useParams, useNavigate,Link } from 'react-router-dom';
import { Loader } from 'rsuite';

export default function Restaurant_edit() {
  const navigate = useNavigate()
  const params = useParams();
  const [details, setDetails] = useState()
  const [savedata, setSavedata] = useState()
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    get_Data()
  }, []);

  const get_Data = async () => {
    try {
      let data = await edit_restaurants(params.id)
      setDetails(data.data)
      setLoading(false);
    } catch (error) {
      console.log(error)
    }
   
  }


  const imguplaod = async (data1) => {
    let imgd = data1.target.files[0]
    let formdata = new FormData()
    formdata.append("file", imgd);
    let data = await fileUpload(formdata)
    setSavedata({ ...savedata, "profilePicture": data.data.data.filePath })
    setDetails(prevData => {
      const updatedMerchantId = { ...prevData.merchantId };
      updatedMerchantId.profilePicture = data.data.data.filePath;
      return { ...prevData, merchantId: updatedMerchantId };
    });

  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSavedata({ ...savedata, [name]: value })
    setDetails((prevDetails) => ({
      ...prevDetails,
      merchantId: {
        ...prevDetails.merchantId,
        [name]: value,
      },
    }));
  };
  // console.log(savedata,'===========================')




  const submitData = async (event) => {
    try {
      event.preventDefault();
      const datt = await update_Restaurent(savedata, params.id)
      navigate("/restaurant_manager")
    } catch (error) {
      console.log(error)
    }

  }

  return (
    <>
      <div className="appview">
        {/* sidebar */}
        <Sidebar />
        {/* \ sidebar */}
        {/* right Content */}
        <div className="right_content">
          {/* Navbar */}
          <Navbar />
          {/* \ Navbar */}
          {/* deshboad */}

          {loading ? (
        <Loader backdrop content="Loading..." vertical size="lg" />
      ) : (
       <>
       
       <section className="wrapper">
            <div className="container-fluid">
              <div className="wrapper_header d-flex justify-content-between align-items-center">
                <div>
                  <h2 className="comp_heading">Manager ID #{details?._id}</h2>
                  <span className="back_route">
                    {" "}

                    <Link to='/restaurant_manager' >Restaurant manager/ </Link>
                    Manager
                    Edit{" "}
                  </span>
                </div>
              </div>
            </div>
          </section>
          <section className="wrapper">
            <div className="container-fluid">
              <div className="row justify-content-center">
                <div className="col-md-8">
                  <div className="profile_page">
                    <div className="modal-header p-0 border-0">
                      <h4>Manager Edit</h4>
                    </div>
                    <div className="modal-body">
                      <form onSubmit={submitData}>
                        <div className="row">
                          <div className="col-12">
                            <div className="img_upload">
                              <label htmlFor="im_file4">
                                {" "}
                                <img src={details?.merchantId?.profilePicture ? details?.merchantId?.profilePicture : "/assets/images/user3.png"} alt="" />{" "}
                                <i className="fa-solid fa-camera" />
                              </label>
                              <input type="file" onChange={(e) => imguplaod(e)} className="d-none" id="im_file4" />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <lable htmlFor="fname1">First Name</lable>
                              <input
                                type="text"
                                name="firstName"
                                id="fname1"
                                className="form-control"
                                defaultValue={details?.merchantId?.firstName}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <lable htmlFor="lname">Last Name</lable>
                              <input
                                type="text"
                                name="lastName"
                                id="lname"
                                className="form-control"
                                defaultValue={details?.merchantId?.lastName}
                                onChange={handleInputChange}

                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <lable htmlFor="num">Telephone Number</lable>
                              <input
                                type="text"
                                name="phoneNo"
                                id="num"
                                className="form-control"
                                defaultValue={details?.phoneNo}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <lable htmlFor="num">Restaurant Name</lable>
                              <input
                                type="text"
                                name="restaurantName"
                                id="num"
                                className="form-control"
                                defaultValue={details?.restaurantName}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <lable htmlFor="gender">Gender</lable>
                              <select
                                name="gender"
                                id="gender"
                                className="form-control"
                                onChange={handleInputChange}
                              >
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Others">Others</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <lable htmlFor="dob">Date Of Birth</lable>
                              <input
                                type="date"
                                name="DOB"
                                id="dob"
                                className="form-control"
                                defaultValue="2000-05-05"
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <lable htmlFor="add">Address</lable>
                              <input
                                type="text"
                                name="fullAddress"
                                id="add"
                                className="form-control"
                                defaultValue={details?.merchantId?.location?.fullAddress}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="col-12 pt-3">
                            <button
                              type="submit"
                              className="btn btn-success"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* \ deshboad */}
       </>
      )}
        </div>
        {/* \ right Content */}
      </div>

    </>
  )
}
