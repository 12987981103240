import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios";
import Sidebar from '../../layouts/Sidebar'
import Navbar from '../../layouts/Navbar'
import { Link } from 'react-router-dom';
import { changeStatus,GetUser } from '../../services/AuthApi'
import { Loader } from 'rsuite';
import Swal from 'sweetalert2'


export default function UserList() {

  let [data, setData] = useState()
  const [loading, setLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totaldata, setTotaldata] = useState(0);
  const [limit, setLimit] = useState(10);
  const [nhit, setNhit] = useState(0);
  const [filtername ,setFiltername] = useState()

  let token = localStorage.getItem("Token");
  const headers = {
    'Authorization': `Bearer ${token}`
  };

  useEffect(() => {
    getuserData(`pageNo=${currentPage}&limit=${limit}`)
  }, []);



  const getuserData = async(pagination)=>{
    try {
      const dataa = await GetUser(pagination)
      if (dataa.statusCode == 200) {
        // console.log(dataa.data,'=======')
        setData(dataa.data.data)
        setCurrentPage(dataa.data.pageNo)
        setTotalPages(dataa.data.totalPage)
        setLimit(dataa.data.limit)
        setTotaldata(dataa.data.total)
        setNhit(dataa.data.nextHit)
        setLoading(false);
      } else {
        console.log(dataa, '====errrrrrrrrrrrrrrrrrrrrr')
      }
    } catch (error) {
      toast.error("internal server error")
    }
  }

  const handleNextPage = () => {
    getuserData(`pageNo=${nhit}&limit=${limit}`)
  };

   const filterData = (e,name)=>{
    setFiltername( e.target.textContent)
    getuserData(`pageNo=${currentPage}&limit=${limit}&sortBy=${name}&sortOrder=1`)
  }

  const StatusData = (e,name)=>{
    setFiltername( e.target.textContent)
    getuserData(`pageNo=${currentPage}&limit=${limit}&status=${name}&sortOrder=1`)
  }



  const deleteResturants = async (id) => {
    try {
      const requestDelete = {
        method: 'DELETE',
        url: `${process.env.REACT_APP_API_URL}api/admin/users/${id}`,
        headers: headers,
      };
      Swal.fire({
        title: 'Are you sure?',
        text: "Are you sure want to Delete this manager Id?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          axios(requestDelete)
            .then(response => {
              getuserData(`pageNo=${currentPage}&limit=${limit}`)
              Swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
              )
            })
            .catch(error => {
              toast.error(error.response.data.message)
            })
        }
      })
    } catch (error) {
      toast.error("internal server error")
    }
  }


  const change_Status = async (id) => {
    let dd = await changeStatus(id)
    if(dd.statusCode==200){
      toast.success(dd.message)
    }else{
      toast.error(dd.message)
    }
    getuserData(`pageNo=${currentPage}&limit=${limit}`)
  }

  return (
    <>
    <ToastContainer />
      <div className="appview">
      {/* sidebar */}
      <Sidebar />
      {/* \ sidebar */}
      {/* right Content */}
      <div className="right_content">
        {/* Navbar */}
        <Navbar />
        {/* \ Navbar */}
        {/* deshboad */}
        {loading ? (
        <Loader backdrop content="Loading..." vertical size="lg" />
      ) : (
     <>
<section className="wrapper">
          <div className="container-fluid">
            <div className="wrapper_header d-flex justify-content-between align-items-center">
              <div>
                <h2 className="comp_heading">General User</h2>
                <h4 className="comp_sub_heading">
                  Here is your general User list data
                </h4>
              </div>
              <div className="btn-group fiter">
                <button
                  type="button"
                  className="fiter_btn"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="fa-solid fa-sliders me-3 filter_icon" /> {filtername? filtername : "Filter"}
                  <i className="fa-solid fa-angle-down ms-auto" />
                </button>
                <ul className="dropdown-menu dropdown-menu-end">
                  <li>
                  <Link className="dropdown-item" onClick={(e)=>filterData(e,"fullName")}  >
                  Full Name
                    </Link>
                  </li>
                  <li>

                  <Link className="dropdown-item" onClick={(e)=>filterData(e,"created")}  >
                  Created
                    </Link>

                  </li>
                  <li>
                  <Link className="dropdown-item" onClick={(e)=>StatusData(e,"ACTIVE")}  >
                  Registered
                    </Link>
                  </li>
                  <li>
                  <Link className="dropdown-item" onClick={(e)=>StatusData(e,"INACTIVE")}  >
                  Unregistered
                    </Link>
                  </li>
                  <li>
                  <Link className="dropdown-item" onClick={(e)=>filterData(e,"location")}  >
                  Location
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className="wrapper">
          <div className="container-fluid">
            <div className="responsive-table">
              <div className="space_t w-100 py-xxl-4 py-lg-3" />
              <table className="admin_table table">
                <thead className="table_header">
                  <tr>
                    <th>
                      <div className="d-flex align-items-end justify-content-start">
                        User ID
                        <span className="d-inline-flex flex-column up_down_icon">
                          <i className="fa-solid fa-caret-up" />
                          <i className="fa-solid fa-caret-down" />
                        </span>
                      </div>
                    </th>
                    <th>
                      <div className="d-flex align-items-end justify-content-start">
                        Join Date
                        <span className="d-inline-flex flex-column up_down_icon">
                          <i className="fa-solid fa-caret-up" />
                          <i className="fa-solid fa-caret-down" />
                        </span>
                      </div>
                    </th>
                    <th>
                      <div className="d-flex align-items-end justify-content-start">
                        Customer Name
                        <span className="d-inline-flex flex-column up_down_icon">
                          <i className="fa-solid fa-caret-up" />
                          <i className="fa-solid fa-caret-down" />
                        </span>
                      </div>
                    </th>
                    <th>
                      <div className="d-flex align-items-end justify-content-start">
                        Location
                        <span className="d-inline-flex flex-column up_down_icon">
                          <i className="fa-solid fa-caret-up" />
                          <i className="fa-solid fa-caret-down" />
                        </span>
                      </div>
                    </th>
                    <th>
                      <div className="d-flex align-items-end justify-content-start">
                        Status
                        <span className="d-inline-flex flex-column up_down_icon">
                          <i className="fa-solid fa-caret-up" />
                          <i className="fa-solid fa-caret-down" />
                        </span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody className="table_body">

                  {data ?
                    data?.map((list) =>
                      <tr key={list?._id}>
                        <td data-label="Customer ID">{list?._id}</td>
                        <td data-label="Restaurant name">{list?.createdAt}</td>
                        <td data-label="Customer Name">{list?.fullName}</td>
                        <td data-label="Total Spent">{list?.location?.fullAddress} </td>
                        <td data-label="Membership">

                          {list?.status == "ACTIVE" ?

                            <Link onClick={() => change_Status(list?._id)} className="t_btn reg" >Registered</Link> :
                            <Link onClick={() => change_Status(list?._id)} className="t_btn " >Unregistered</Link>
                          }


                          <div className="btn-group t_dropdown">
                            <i
                              className="fa-solid fa-ellipsis"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            />
                            <ul className="dropdown-menu dropdown-menu-end">
                              <li>
                                {/* <a href="user-id-view.html" className="dropdown-item">
                          View Detail
                        </a> */}

                                <Link className="dropdown-item" to={`/user_view/${list?._id}`}> View Detail</Link>

                              </li>
                              {/* <li>
                                <Link className="dropdown-item" to={`/editUser/${list?._id}`}> Edit</Link>

                              </li> */}
                              <li>

                                <button
                                  className="dropdown-item"
                                  onClick={() => deleteResturants(list?._id)}
                                >
                                  Delete
                                </button>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                    )
                    : <span>No Data found</span>}


                </tbody>
              </table>
              <div className="table_footer">
                <div className="row align-items-center g-3">
                  <div className="col-md-6 text-md-start text-center">
                  <p>Showing {currentPage} from {totaldata} data</p>
                  </div>
                  <div className="col-md-6">
                        <nav aria-label="Page navigation example">
                          <ul className="pagination ms-auto me-md-0 me-auto">
                            <li className="page-item">
                              <Link className="page-link" onClick={() => handleNextPage(0)} >
                                <span aria-hidden="true">
                                  <i className="fa-solid fa-angles-left" />
                                </span>
                              </Link>
                              {/* <a className="page-link" href="#" aria-label="Previous">
                            <span aria-hidden="true">
                                <i className="fa-solid fa-angles-left" />
                              </span>
                            </a> */}
                            </li>
                            {[...Array(totalPages)].map((e, i) =>
                              <li className="page-item" key={i}>
                                <Link className={currentPage == i + 1 ? "page-link active" : "page-link"}>
                                  {i + 1}
                                </Link>
                                {/* <a className="page-link active" href="#">
                            {i+1}
                          </a> */}
                              </li>
                            )}
                            {/* <li className="page-item">
                            <a className="page-link" href="#">
                              2
                            </a>
                          </li>
                          <li className="page-item">
                            <a className="page-link" href="#">
                              3
                            </a>
                          </li> */}
                            <li className="page-item">
                              <Link className="page-link" onClick={() => handleNextPage()} >
                                <span aria-hidden="true">
                                  <i className="fa-solid fa-angles-right" />
                                </span>
                              </Link>
                              {/* <a className="page-link" href="#" aria-label="Next">
                              <span aria-hidden="true">
                                <i className="fa-solid fa-angles-right" />
                              </span>
                            </a> */}
                            </li>
                          </ul>
                        </nav>
                      </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* {/* modal * /} */}
        <div
          className="modal fade"
          id="user-delete"
          tabIndex={-1}
          aria-labelledby="user-deleteLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-sm modal-dialog-centered">
            <div className="modal-content py-3">
              <div className="justify-content-center modal-header p-0 border-0">
                <h4 className="">User Delete</h4>
                <button
                  type="button"
                  className="btn_close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fa-solid fa-xmark" />
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="row">
                    <div className="col-12">
                      <p className="px-3 text-center">
                        Are you sure want to Delete this User Id?
                      </p>
                    </div>
                    <div className="col-12 pt-3 d-flex align-items-center justify-content-center">
                      <button
                        type="button"
                        className="ed_pbtn btn mx-1"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        No
                      </button>
                      <button
                        type="button"
                        className="ed_pbtn btn mx-1 del_btn"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* \ deshboad */}
     </>
      )}


        
      </div>
      {/* \ right Content */}
    </div>

    </>
  
  )
}
